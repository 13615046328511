import React, { useState, useContext, useEffect } from "react";
import HomeBannerSearch from "../Components/homeBannerSearch";
import { SettingsContext } from "../DataFactory/useSettings";
import GView from "../Elements/GView";
import HomeContent from "../Components/homeContent";
import { ImgUrl } from "../services";
import GText from "../Elements/GText";
import ItemView from "../Elements/gItemListView";
import PreferredItemView from "../Elements/gItemPreferredListView";
import useWindowSize from "../DataFactory/useWindowSize";
// import PopularItemData from "../jsonData/itemJson.json";
import { useNavigate } from "react-router-dom";
import { UserSearchInfoContext } from "../DataFactory/useSearchInfo";
import { UserLocContext } from "../DataFactory/useLocation";
import ItemFiltersView from "../Components/itemFilters";
import { useSnackbar } from "notistack";
import { ApiService } from "../services";
import useDataFctory from "../useDataFactory";
import Placeholder from "../Placeholders/Placeholder";
import GButton from "../Elements/GButton";
import GMap from "../Components/itemLocation";
import moment from "moment-timezone";
import { GCardShimmer } from "../Elements/gShimmer";
import styled from "styled-components";
import LocationIcon from "../assets/images/marker.png";
import GSelectbox from "../Elements/gSelectbox";
import ItemInfoModal from "../Components/itemInfo";
import Modal from "react-modal";

const LocationDiv = styled.div`
  svg {
    fill: ${({ theme }) => theme.body};
    height: "100%";
  }
`;

const Home = () => {
  const navigate = useNavigate();
  const { settings } = useContext(SettingsContext);
  const { width } = useWindowSize();

  const customStyles = {
    content: {
      width: "400px",
    },
  };

  const { userLocation } = useContext(UserLocContext);
  const { searchInfo } = useContext(UserSearchInfoContext);
  const [btnDisabled, setBtnDisabled] = useState(false);
  const [page, setPage] = useState(1);
  const [isLoading, setIsLoading] = useState(true);
  const [selectedItem, setSelectedItem] = useState("");
  const [itemInfoVisible, setItemInfoVisible] = useState(false);
  const { enqueueSnackbar } = useSnackbar();
  const [selectedFilters, setSelectedFilters] = useState(
    localStorage.getItem("searchFilters")
      ? JSON.parse(localStorage.getItem("searchFilters"))
      : {}
  );
  const [showAdvancedFilters, setShowAdvancedFilters] = useState(
    localStorage.getItem("showAdvanced") || "false"
  );
  const {
    loading,
    Shimmer,
    data: filtersData,
  } = useDataFctory("product_filters", false);
  const [itemsData, setItemsData] = useState({
    data: [],
  });
  const [preferredItems, setPreferredItems] = useState([]);

  function getItems() {
    // let endDate = new Date(searchInfo?.starts);
    let sendData = {
      lat: userLocation?.lat,
      lng: userLocation?.lng,
      starts: searchInfo?.starts,
      ends: searchInfo?.ends,
      radius: searchInfo?.radius,
      48: searchInfo[48],
      // ends: moment(
      //   endDate.setDate(endDate.getDate() + parseInt(searchInfo?.guests))
      // ).format("YYYY-MM-DD"), //searchInfo?.ends,
      units:
        settings.multiple_units == 0 ? [] : [{ users: searchInfo?.guests }],
    };
    if (settings?.multiple_units <= 0) {
      delete sendData.units;
    }
    if (selectedFilters?.radius) {
      delete selectedFilters.radius;
    }
    if (selectedFilters[48]) {
      delete selectedFilters[48];
    }
    ApiService({
      method: "Post",
      route: "c/products?page=" + page,
      body: { ...sendData, ...selectedFilters },
    })
      .then((response) => {
        console.log(response);

        if (response.status === 26) {
          enqueueSnackbar(
            "NETWORK FAILED. Please check your internet connection."
          );
          return;
        } else {
          if (response?.data?.status_code === 1) {
            setItemsData(response.data.data);
            setPreferredItems(response.data?.preferred_listings || []);
          } else {
            enqueueSnackbar(response?.data?.message);
          }
        }

        setIsLoading(false);
      })
      .catch((error) => {
        console.log(error);
      });
  }

  useEffect(() => {
    setIsLoading(true);
    console.log(selectedFilters);
    if (page == 1) {
      getItems();
    } else {
      setPage(1);
    }

    localStorage.setItem("searchFilters", JSON.stringify(selectedFilters));
    localStorage.setItem("showAdvanced", showAdvancedFilters);
  }, [selectedFilters]);

  useEffect(() => {
    if (page > 1) {
      // let endDate = new Date(searchInfo?.starts);
      let sendData = {
        lat: userLocation?.lat,
        lng: userLocation?.lng,
        starts: searchInfo?.starts,
        ends: searchInfo?.ends,
        radius: searchInfo?.radius,
        48: searchInfo[48] || "",
        // ends: moment(
        //   endDate.setDate(endDate.getDate() + parseInt(searchInfo?.guests))
        // ).format("YYYY-MM-DD"),
        units:
          settings.multiple_units == 0 ? [] : [{ users: searchInfo?.guests }],
      };

      if (settings.multiple_units <= 0) {
        delete sendData.units;
      }
      if (selectedFilters[48]) {
        delete selectedFilters[48];
      }
      console.log(sendData);
      ApiService({
        method: "Post",
        route: "c/products?page=" + page,
        body: sendData,
      })
        .then((response) => {
          console.log(response);

          if (response.status === 26) {
            enqueueSnackbar(
              "NETWORK FAILED. Please check your internet connection."
            );
            return;
          } else {
            if (response?.data?.status_code === 1) {
              setItemsData({
                ...itemsData,
                ...response.data.data,
                data: [...itemsData.data, ...response.data.data?.data],
              });
            } else {
              enqueueSnackbar(response?.data?.message);
            }
          }

          setIsLoading(false);
          setBtnDisabled(false);
        })
        .catch((error) => {
          console.log(error);
        });
    } else {
      getItems();
    }
  }, [page]);

  return (
    <>
      <GView
        className="main-content"
        style={{ padding: width > 767 ? "20px 0" : "20px" }}
      >
        <GView
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            height: "auto",
            minHeight: "400px",
            backgroundImage: `url(${
              ImgUrl("website") + "/" + settings?.web?.web_home_header
            })`,
            backgroundSize: "cover",
            backgroundPosition: "100%",
            backgroundRepeat: "no-repeat",
            borderRadius: "5px",
            textAlign: "center",
            position: "relative",
          }}
        >
          <GText
            g2="true"
            bold
            text={settings?.web?.web_home_title}
            style={{
              color: settings?.web?.web_home_header ? "#fff" : "#000",
              marginTop: "auto",
              textShadow: settings?.web?.web_home_header
                ? "0.5px 0.5px #f9f9f9"
                : "none",
              fontFamily: "Open Sans, sans-serif",
              fontSize: width > 767 ? "28px" : "24px",
              width: width <= 991 ? "90%" : "45%",
              marginBottom:
                width <= 991
                  ? "0"
                  : !settings?.web?.web_home_desc
                  ? "auto"
                  : "5px",
            }}
          />
          {settings?.web?.web_home_desc && (
            <GText
              text={settings?.web?.web_home_desc}
              style={{
                padding: "10px 0",
                fontSize: "16px",
                color: settings?.web?.web_home_header ? "#fff" : "#000",
                textShadow: settings?.web?.web_home_header
                  ? "0.5px 0.5px #f9f9f9"
                  : "none",
                width: width <= 991 ? "90%" : "35%",
                marginBottom: width <= 991 ? 0 : "auto",
              }}
            />
          )}

          <HomeBannerSearch
            onSearchOld={() => {
              navigate(`/${settings?.product_display?.toLowerCase()}`, {
                state: { title: settings?.product_display },
              });
            }}
            onSearch={() => {
              if (page == 1) {
                getItems();
              } else {
                setPage(1);
              }
            }}
            setShowAdvancedFilters={setShowAdvancedFilters}
            showAdvancedFilters={showAdvancedFilters}
            filtersData={filtersData}
            setSelectedFilters={setSelectedFilters}
            selectedFilters={selectedFilters}
          />
        </GView>

        <GView
          style={{
            display: "flex",
            paddingTop: "20px",
            height:
              itemsData?.data?.length == 0
                ? null
                : width > 767
                ? "calc(100vh - 140px)"
                : "auto",
            overflow: "hidden",
          }}
        >
          <GView
            style={{
              flex: "100%",
            }}
          >
            <GText
              g3
              bold
              text={`${itemsData?.total || ""} ${settings?.product_display}`}
            />

            <GView
              style={{
                display: "flex",
                gap: "13px",
                alignItems: "baseline",
                margin: "15px 0 10px",
                flexWrap: "wrap",
              }}
            >
              {filtersData && filtersData.length > 0 ? (
                <>
                  {filtersData
                    .filter((f) => {
                      if (showAdvancedFilters == "true") {
                        return f;
                      } else {
                        return f.advanced == 0;
                      }
                    })
                    .map((filters, i) => {
                      return (
                        <React.Fragment key={i}>
                          <GSelectbox
                            placeholder={filters.title || "All"}
                            defaultvalue={
                              selectedFilters[filters.identifier.toString()]
                            }
                            data={filters.value}
                            id="item_status_filter"
                            option_key="title"
                            option_value="value"
                            onChange={(newVal) => {
                              console.log(selectedFilters);
                              console.log(newVal);
                              //return;

                              let fData = {};
                              fData[filters?.identifier?.toString()] =
                                newVal || "";

                              setSelectedFilters({
                                ...selectedFilters,
                                ...fData,
                              });
                            }}
                            style={{
                              border: "1px solid #e2e2e2",
                              height: "40px",
                              minWidth: "150px",
                            }}
                          />
                        </React.Fragment>
                      );
                    })}
                </>
              ) : (
                ""
              )}
            </GView>

            {isLoading === true ? (
              <GCardShimmer />
            ) : itemsData.data.length === 0 ? (
              <Placeholder type={"item"} />
            ) : (
              <>
                <GView
                  style={{
                    display: "flex",
                    flexDirection: width <= 767 ? "column" : "row",
                    flex: 1,
                  }}
                >
                  <GView style={{ flex: 1 }}>
                    <GView
                      style={{
                        display:
                          itemsData.data.length == 1 && width > 767
                            ? "inline-grid"
                            : "grid",
                        gridTemplateColumns:
                          width <= 350
                            ? "repeat(auto-fit, minmax(300px, 1fr))"
                            : "repeat(auto-fit, minmax(250px, 2fr))",
                        gridGap: "20px",
                        padding: "10px 0",
                        height: width > 767 ? "calc(100vh - 300px)" : "auto",
                        overflow: "overlay",
                      }}
                    >
                      {itemsData.data.map((item, i) => {
                        return (
                          <ItemView
                            data={item}
                            key={i}
                            settings={settings}
                            onSelectItem={() => {
                              navigate(
                                `/${settings?.product_display?.toLowerCase()}/${
                                  item.id
                                }`,
                                {
                                  state: {
                                    unit_id:
                                      item?.product_units &&
                                      item?.product_units.length > 0
                                        ? item?.product_units[0]?.id
                                        : "",
                                    title: item?.custom_fields?.title,
                                  },
                                }
                              );
                            }}
                          />
                        );
                      })}
                    </GView>

                    {itemsData.next_page_url && (
                      <GButton
                        variant="condensed"
                        loading={btnDisabled}
                        children={"Load More"}
                        type="button"
                        onClick={() => {
                          setBtnDisabled(true);
                          setPage(page + 1);
                        }}
                        style={{
                          display: "flex",
                          margin: "10px auto",
                          minWidth: "150px",
                        }}
                      />
                    )}
                  </GView>

                  <GView style={{ flex: 1, height: "100%" }}>
                    <LocationDiv>
                      <GMap
                        lat={itemsData?.data[0]?.lat}
                        lng={itemsData?.data[0]?.lng}
                        address={"itemInfo?.address"}
                        height="calc(100vh - 230px)"
                        markers={itemsData.data.map((item, i) => {
                          return {
                            lat: parseFloat(item?.lat),
                            lng: parseFloat(item?.lng),
                            icon: LocationIcon,
                            title: "props.address",
                            type: "address",
                            id: item.id,
                          };
                        })}
                        onSelectItemLocation={(itemId) => {
                          setSelectedItem(itemId);
                          setItemInfoVisible(true);
                        }}
                        setSelectedItem={setSelectedItem}
                      />
                      <GView
                        style={{
                          display: "flex",
                          alignItems: "center",
                          padding: "10px 0 0",
                        }}
                      ></GView>
                    </LocationDiv>
                  </GView>
                </GView>
              </>
            )}
          </GView>
        </GView>

        {preferredItems && preferredItems.length > 0 && (
          <>
            <hr
              style={{
                margin: "20px 0",
                borderTop: "0px",
                borderBottom: "1px solid #eee",
              }}
            />
            <GText
              g2={"true"}
              bold
              // text={`New Listings in ${userLocation?.address}`}
              text={`New Listings`}
              style={{ margin: "40px 0 10px" }}
            />

            <GView
              style={{
                display:
                  preferredItems.length == 1 && width > 767
                    ? "inline-grid"
                    : "grid",
                gridTemplateColumns:
                  width <= 350
                    ? "repeat(auto-fit, minmax(300px, 1fr))"
                    : "repeat(auto-fit, minmax(310px, 2fr))",
                gridGap: "20px",
                padding: "20px 0",
              }}
            >
              {preferredItems.map((item, i) => {
                return (
                  <PreferredItemView
                    data={item}
                    key={i}
                    settings={settings}
                    onSelectItem={() => {
                      navigate(
                        `/${settings?.product_display?.toLowerCase()}/${
                          item.id
                        }`,
                        {
                          state: {
                            unit_id:
                              item?.product_units &&
                              item?.product_units.length > 0
                                ? item?.product_units[0]?.id
                                : "",
                            title: item?.custom_fields?.title,
                          },
                        }
                      );
                    }}
                  />
                );
              })}
            </GView>
          </>
        )}

        {/* <HomeContent
          data={settings?.plugins?.homepage}
          content={
            settings?.homepage_content
              ? JSON.parse(settings?.homepage_content)
              : settings?.homepage_content
          }
        /> */}
      </GView>

      <Modal
        isOpen={itemInfoVisible}
        className="modal"
        overlayClassName="modal-overlay"
        onRequestClose={() => {
          setItemInfoVisible(false);
        }}
        style={{
          content: {
            width: "350px",
            top: "50%",
            left: "50%",
            right: "auto",
            bottom: "auto",
            marginRight: "-50%",
            transform: "translate(-50%, -50%)",
            height: "auto",
          },
        }}
        contentLabel="Item Info Modal"
      >
        <ItemInfoModal
          item={itemsData.data.filter((item) => item.id == selectedItem)}
          itemInfoVisible={itemInfoVisible}
          setItemInfoVisible={setItemInfoVisible}
          settings={settings}
          onSelectItem={(item) => {
            setItemInfoVisible(false);
            navigate(
              `/${settings?.product_display?.toLowerCase()}/${item.id}`,
              {
                state: {
                  unit_id:
                    item?.product_units && item?.product_units.length > 0
                      ? item?.product_units[0]?.id
                      : "",
                  title: item?.custom_fields?.title,
                },
              }
            );
          }}
        />
      </Modal>
    </>
  );
};

export default Home;
