import React, { useState, useContext, useEffect } from "react";
import { ApiService } from "../services";
import HomeBannerSearch from "../Components/homeBannerSearch";
import { SettingsContext } from "../DataFactory/useSettings";
import { UserLocContext } from "../DataFactory/useLocation";
import { UserSearchInfoContext } from "../DataFactory/useSearchInfo";
import GView from "../Elements/GView";
import ItemView from "../Elements/gItemListView";
import useWindowSize from "../DataFactory/useWindowSize";
import ItemFiltersView from "../Components/itemFilters";
import GText from "../Elements/GText";
import useDataFctory from "../useDataFactory";
import { useSnackbar } from "notistack";
import Placeholder from "../Placeholders/Placeholder";
import { GCardShimmer } from "../Elements/gShimmer";
import GButton from "../Elements/GButton";
import { useNavigate } from "react-router-dom";
import GMap from "../Components/itemLocation";
import styled from "styled-components";
import LocationIcon from "../assets/images/marker.png";
import moment from "moment-timezone";

const LocationDiv = styled.div`
  svg {
    fill: ${({ theme }) => theme.body};
    height: "100%";
  }
`;

const ItemList = () => {
  const navigate = useNavigate();
  const { settings } = useContext(SettingsContext);
  const { userLocation } = useContext(UserLocContext);
  const { searchInfo } = useContext(UserSearchInfoContext);
  const [isLoading, setIsLoading] = useState(true);
  const { enqueueSnackbar } = useSnackbar();
  const { width } = useWindowSize();
  const {
    loading,
    Shimmer,
    data: filtersData,
  } = useDataFctory("product_filters", false);
  const [itemsData, setItemsData] = useState({
    data: [],
  });
  const [page, setPage] = useState("1");
  const [btnDisabled, setBtnDisabled] = useState(false);
  const [selectedFilters, setSelectedFilters] = useState({});

  function getItems() {
    // let endDate = new Date(searchInfo?.starts);
    let sendData = {
      lat: userLocation?.lat,
      lng: userLocation?.lng,
      starts: searchInfo?.starts,
      ends: searchInfo?.ends,
      // ends: moment(
      //   endDate.setDate(endDate.getDate() + parseInt(searchInfo?.guests))
      // ).format("YYYY-MM-DD"), //searchInfo?.ends,
      units:
        settings.multiple_units == 0 ? [] : [{ users: searchInfo?.guests }],
    };
    if (settings?.multiple_units <= 0) {
      delete sendData.units;
    }
    ApiService({
      method: "Post",
      route: "c/products?page=" + page,
      body: { ...sendData, ...selectedFilters },
    })
      .then((response) => {
        console.log(response);

        if (response.status === 26) {
          enqueueSnackbar(
            "NETWORK FAILED. Please check your internet connection."
          );
          return;
        } else {
          if (response?.data?.status_code === 1) {
            setItemsData(response.data.data);
          } else {
            enqueueSnackbar(response?.data?.message);
          }
        }

        setIsLoading(false);
      })
      .catch((error) => {
        console.log(error);
      });
  }

  useEffect(() => {
    setIsLoading(true);
    console.log(selectedFilters);
    if (page == 1) {
      getItems();
    } else {
      setPage("1");
    }
  }, [selectedFilters]);

  useEffect(() => {
    if (page > 1) {
      // let endDate = new Date(searchInfo?.starts);
      let sendData = {
        lat: userLocation?.lat,
        lng: userLocation?.lng,
        starts: searchInfo?.starts,
        ends: searchInfo?.ends,
        // ends: moment(
        //   endDate.setDate(endDate.getDate() + parseInt(searchInfo?.guests))
        // ).format("YYYY-MM-DD"),
        units:
          settings.multiple_units == 0 ? [] : [{ users: searchInfo?.guests }],
      };

      if (settings.multiple_units <= 0) {
        delete sendData.units;
      }

      console.log(sendData);
      ApiService({
        method: "Post",
        route: "c/products?page=" + page,
        body: sendData,
      })
        .then((response) => {
          console.log(response);

          if (response.status === 26) {
            enqueueSnackbar(
              "NETWORK FAILED. Please check your internet connection."
            );
            return;
          } else {
            if (response?.data?.status_code === 1) {
              itemsData.data = [...itemsData.data, response.data.data];
              setItemsData({ ...itemsData });
            } else {
              enqueueSnackbar(response?.data?.message);
            }
          }

          setIsLoading(false);
          setBtnDisabled(false);
        })
        .catch((error) => {
          console.log(error);
        });
    } else {
      getItems();
    }
  }, [page]);

  return (
    <GView className="main-content" style={{ padding: "0px 0" }}>
      <HomeBannerSearch
        mainStyle={{ border: "1px solid #e2e2e2", marginBottom: "0px" }}
        onSearch={() => {
          if (page == 1) {
            getItems();
          } else {
            setPage("1");
          }
        }}
      />

      <GView
        style={{
          display: "flex",
          paddingTop: "20px",
          height: "calc(100vh - 200px)",
          overflow: "hidden",
        }}
      >
        {filtersData && filtersData.length > 0 && width > 767 && (
          <GView
            style={{
              flex:
                width > 1200 && width <= 1550
                  ? "25%"
                  : width > 1550
                  ? "20%"
                  : "30%",
            }}
          >
            <ItemFiltersView
              data={filtersData}
              applyBtn={true}
              submitBtn={false}
              onSubmit={(sValues) => {
                console.log(sValues);

                let fData = {};
                filtersData.map((f) => {
                  console.log(sValues[f.identifier]);
                  if (sValues[f.identifier]) {
                    fData[f?.identifier?.toString()] =
                      sValues[f.identifier] || "";
                  }
                });

                console.log(fData);
                //getItems();
                setSelectedFilters(fData);
              }}
            />
          </GView>
        )}

        <GView
          style={{
            flex:
              filtersData && filtersData.length > 0
                ? width > 1200 && width <= 1550
                  ? "75%"
                  : width > 1550
                  ? "80%"
                  : width <= 767
                  ? "100%"
                  : "70%"
                : "100%",
          }}
        >
          {isLoading === true ? (
            <GCardShimmer />
          ) : itemsData.data.length === 0 ? (
            <Placeholder type={"item"} />
          ) : (
            <>
              <GView
                style={{
                  display: "flex",
                  flexDirection: "row",
                  flex: 1,
                }}
              >
                <GView style={{ flex: 1, height: "100%" }}>
                  <LocationDiv>
                    <GMap
                      lat={itemsData?.data[0]?.lat}
                      lng={itemsData?.data[0]?.lng}
                      address={"itemInfo?.address"}
                      height="calc(100vh - 200px)"
                      markers={itemsData.data.map((item, i) => {
                        return {
                          lat: parseFloat(item?.lat),
                          lng: parseFloat(item?.lng),
                          icon: LocationIcon,
                          title: "props.address",
                          type: "address",
                        };
                      })}
                    />
                    <GView
                      style={{
                        display: "flex",
                        alignItems: "center",
                        padding: "10px 0 0",
                      }}
                    ></GView>
                  </LocationDiv>
                </GView>

                <GView>
                  <GText
                    g3
                    bold
                    text={`${itemsData?.total} ${settings?.product_display}`}
                    style={{ marginLeft: 15 }}
                  />

                  <GView
                    style={{
                      display: "grid",
                      gridTemplateColumns:
                        width <= 350
                          ? "repeat(auto-fit, minmax(300px, 1fr))"
                          : "repeat(auto-fit, minmax(300px, 1fr))",
                      gridGap: "20px",
                      marginTop: "10px",
                      padding: "10px 0",
                      height: "calc(100vh - 270px)",
                      overflow: "overlay",
                    }}
                  >
                    {itemsData.data.map((item, i) => {
                      return (
                        <ItemView
                          data={item}
                          key={i}
                          settings={settings}
                          onSelectItem={() => {
                            navigate(
                              `/${settings?.product_display?.toLowerCase()}/${
                                item.id
                              }`,
                              {
                                state: {
                                  unit_id:
                                    item?.product_units &&
                                    item?.product_units.length > 0
                                      ? item?.product_units[0]?.id
                                      : "",
                                  title: item?.custom_fields?.title,
                                },
                              }
                            );
                          }}
                        />
                      );
                    })}
                  </GView>

                  {itemsData.next_page_url && (
                    <GButton
                      variant="condensed"
                      loading={btnDisabled}
                      children={"Load More"}
                      type="button"
                      onClick={() => {
                        setBtnDisabled(true);
                        setPage(page + 1);
                      }}
                      style={{
                        display: "flex",
                        margin: "10px auto",
                        minWidth: "150px",
                      }}
                    />
                  )}
                </GView>
              </GView>
            </>
          )}
        </GView>
      </GView>
    </GView>
  );
};

export default ItemList;
