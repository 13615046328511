import React, { useState, useEffect, useContext } from "react";
import { ApiService, ImgUrl } from "../services";
import { useSnackbar } from "notistack";
import { ReactComponent as ArrowBackIos } from "../assets/images/arrowBack.svg";
import GButton from "../Elements/GButton";
import { GCardShimmer } from "../Elements/gShimmer";
import { GInlineContent, GTableContent } from "../Elements/gContent";
import Modal from "react-modal";
import CancelReasonsView from "../Components/orderCancelReasons";
import OrderFeedback from "../Components/orderFeedback";
import GText from "../Elements/GText";
import GView from "../Elements/GView";
import OrderMessages from "../Components/orderMesaages";
import { ReactComponent as PhoneIcon } from "../assets/images/phone.svg";
import { ReactComponent as ChatIcon } from "../assets/images/chat.svg";
import { ReactComponent as NavigateIcon } from "../assets/images/navigation.svg";
import GInfo from "../Elements/gInfo";
import GAvtar from "../Elements/GAvtar";
import GMap from "../Components/itemLocation";

function getIOSVersion() {
  var match = window.navigator.appVersion.match(/OS (\d+)_(\d+)_?(\d+)?/);
  return match ? match[1] : undefined;
}

export default function OrderDetail(props) {
  const [orderData, setOrderData] = useState({});
  const [ocrvisible, setOcrVisible] = useState(false);
  const [ofvisible, setOfvisible] = useState(false);
  const [omvisible, setOmvisible] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [orderMessages, setOrderMessages] = useState([]);
  const {
    status_display,
    host,
    payment_summary,
    order_review,
    order_fields,
    cancelled_log,
  } = orderData;
  const { enqueueSnackbar } = useSnackbar();

  const getOrderDetail = () => {
    ApiService({ method: "GET", route: "c/orders/" + props.linkedId })
      .then((response) => {
        console.log(response);

        if (response.status === 26) {
          enqueueSnackbar(
            "NETWORK FAILED. Please check your internet connection."
          );
          return;
        } else {
          if (response?.data?.status_code === 1) {
            setOrderData(response.data.data);
            setIsLoading(false);
            setOrderMessages(response.data.data?.order_messages || []);
          } else {
            enqueueSnackbar(response?.data?.message);
          }
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  useEffect(() => {
    getOrderDetail();
  }, [props.linkedId]);

  if (props.orderInfovisible === false) {
    return null;
  }
  return (
    <React.Fragment>
      <div className="modal-header">
        <ArrowBackIos
          className="backIcon"
          onClick={() => props.setOrderInfovisible(false)}
        />{" "}
        Activity #{props.linkedId}
        {status_display?.title === "Pending" && (
          <GButton
            variant="linkable"
            children="Problem?"
            type="button"
            style={{ marginLeft: "auto" }}
            onClick={() => setOcrVisible(true)}
          />
        )}
        {status_display?.title === "Completed" && !order_review ? (
          <GButton
            variant="linkable"
            children="Feedback"
            type="button"
            style={{ marginLeft: "auto" }}
            onClick={() => setOfvisible(true)}
          />
        ) : (
          ""
        )}
      </div>

      <div className="modal-body" style={{ padding: "0 25px" }}>
        {isLoading === true ? (
          <GCardShimmer />
        ) : (
          <React.Fragment>
            {orderData?.product?.images &&
              orderData?.product?.images.length > 0 && (
                <img
                  src={
                    ImgUrl("item") + "/" + orderData?.product?.images[0]?.photo
                  }
                  style={{ width: "100%" }}
                />
              )}
            <GView
              style={{
                display: "flex",
                flexDirection: "row",
                margin: "10px 0 15px",
                alignItems: "center",
              }}
            >
              <GView style={{ flex: "1" }}>
                <GText
                  g4
                  semi
                  text={orderData?.product_title}
                  style={{ margin: "0px 0" }}
                />
                <GText
                  g5
                  med
                  text={`Booked at  ${orderData.created}`}
                  style={{ margin: "0px 0", fontSize: "13.5px" }}
                />
              </GView>
              <span
                className="orderStatus"
                style={{
                  background: `#${status_display?.color2}`,
                  color: `#${status_display?.color1}`,
                  marginLeft: "15px",
                  height: "fit-content",
                  borderRadius: "5px",
                  padding: "3px 12px",
                  fontSize: "13.5px",
                  textTransform: "uppercase",
                  fontWeight: 500,
                }}
              >
                {status_display?.title}
              </span>{" "}
            </GView>

            {status_display?.title === "Cancelled" && (
              <GText
                style={{
                  padding: "10px",
                  color: "#F96D77",
                  fontWeight: "450",
                  letterSpacing: "0.2px",
                  borderRadius: "5px",
                  boxSizing: "border-box",
                  fontSize: "13.5px",
                  marginBottom: "10px",
                  background: "rgba(249, 109, 119, 0.2)",
                }}
                text={cancelled_log.reason}
              />
            )}
            {orderData?.order_units && orderData?.order_units.length > 1 && (
              <>
                <hr style={{ border: 0, borderBottom: "3px solid #f4f5f9" }} />
                <GText
                  g6
                  med
                  text={`${props.settings?.unit_display} Info`}
                  style={{
                    textTransform: "uppercase",
                    fontStyle: "italic",
                    margin: "15px 0 10px",
                  }}
                />
                {orderData?.order_units.map((unit, i) => {
                  return (
                    <GInlineContent
                      title={`${props.settings?.unit_display} ${i + 1}`}
                      value={`${unit?.users} Guests`}
                    />
                  );
                })}
              </>
            )}

            <hr style={{ border: 0, borderBottom: "3px solid #f4f5f9" }} />
            <GText
              g4
              semi
              text={"Activity Info"}
              style={{
                margin: "15px 0 10px",
              }}
            />
            {orderData?.starts && (
              <GInlineContent title="Starts" value={orderData.starts_display} />
            )}
            {orderData?.ends && (
              <GInlineContent title="Ends" value={orderData.ends_display} />
            )}

            {/* {orderData?.order_units && orderData?.order_units.length == 1 && (
              <GInlineContent
                title="Guests"
                value={orderData?.order_units[0].users}
              />
            )} */}

            <hr style={{ border: 0, borderBottom: "3px solid #f4f5f9" }} />
            <div>
              <div
                style={{
                  display: "flex",
                  paddingBottom: "10px",
                  alignItems: "center",
                }}
              >
                <div style={{ flex: 1 }}>
                  <GText
                    g4
                    semi
                    text={`Hosted by ${host?.title}`}
                    style={{
                      margin: "10px 0 5px",
                    }}
                  />
                  <GText
                    text={`Have a question about your reservation?`}
                    style={{ margin: "0 0 5px" }}
                  />
                  <a
                    target="_blank"
                    rel="noopener noreferrer"
                    href={`tel: ${host?.phone}`}
                    style={{ display: "inherit" }}
                  >
                    <GButton variant="linkable" style={{ padding: 0 }}>
                      {host?.phone}
                    </GButton>
                  </a>
                </div>
                <GAvtar
                  title={host?.title}
                  width={"50px"}
                  height={"50px"}
                  src={
                    host?.thumb_photo
                      ? ImgUrl("host") + "/" + host?.thumb_photo
                      : ""
                  }
                  imgType="host"
                  alt={host?.title}
                  imgStyle={{
                    width: "45px",
                    height: "45px",
                    objectFit: "cover",
                  }}
                />
              </div>
            </div>
            <hr style={{ border: 0, borderBottom: "3px solid #f4f5f9" }} />
            <div>
              <GText
                g4
                semi
                text={`Chat Support`}
                style={{
                  margin: "15px 0 10px",
                }}
              />
              <GText
                text={`The best way to get information is to ask your host directly.`}
                style={{ margin: "0 0 5px" }}
              />
              <GButton
                variant="condensed"
                onClick={() => setOmvisible(true)}
                style={{ margin: "5px 0 10px", borderRadius: "4px" }}
              >
                Message Host
              </GButton>
            </div>

            <hr style={{ border: 0, borderBottom: "3px solid #f4f5f9" }} />

            <GText
              g4
              semi
              text={`${props.settings?.product_display} Location`}
              style={{
                margin: "15px 0 10px",
              }}
            />
            <div
              style={{
                marginBottom: "10px",
              }}
            >
              <GMap
                lat={orderData?.product?.lat}
                lng={orderData?.product?.lng}
                address={orderData?.product?.address}
                height={"200px"}
              />
              <GText
                text={orderData?.product?.address}
                style={{ marginTop: "10px" }}
              />
              <a
                target="_blank"
                rel="noopener noreferrer"
                href={
                  orderData?.product?.adress
                    ? `https://www.google.com/maps?q=${orderData?.product?.lat},${orderData?.product?.lng}`
                    : ""
                }
                style={{ marginLeft: "auto" }}
              >
                <GButton
                  variant="linkable"
                  style={{ fontWeight: 550, fontSize: 14, padding: "7px 0" }}
                >
                  Get Directions
                </GButton>
              </a>
            </div>

            {props.settings?.time_charges == 1 && (
              <>
                <hr style={{ border: 0, borderBottom: "3px solid #f4f5f9" }} />
                <div>
                  <GText
                    g4
                    semi
                    text={`Bill Details`}
                    style={{
                      margin: "15px 0 10px",
                    }}
                  />
                  {payment_summary?.data.map((p) => {
                    return (
                      <GTableContent
                        key={p.title}
                        title={p.title}
                        value={props.settings.currency_symbol + p.value}
                        align="right"
                        lAlign="right"
                        padding="2px 5px"
                      />
                    );
                  })}
                  <hr style={{ border: "0", borderTop: "1px solid #e2e2e2" }} />

                  <GTableContent
                    title="Grand Total"
                    value={
                      props.settings.currency_symbol +
                      payment_summary?.total_display
                    }
                    align="right"
                    lAlign="right"
                    fontSize="15px"
                    color="#000"
                    bold={true}
                  />
                </div>
              </>
            )}
          </React.Fragment>
        )}
        {getIOSVersion() >= 14 && <div style={{ height: "80px" }}></div>}
      </div>

      <Modal
        isOpen={ocrvisible}
        className="modal modalRight"
        overlayClassName="modal-overlay"
        onRequestClose={() => setOcrVisible(false)}
        style={{
          content: {
            left: "auto",
            right: "0",
            top: "0",
            bottom: "0",
            width: "450px",
          },
        }}
        contentLabel="Order cancel Modal"
      >
        <CancelReasonsView
          ocrvisible={ocrvisible}
          setOcrVisible={setOcrVisible}
          linkedId={orderData.id}
          onSubmit={() => {
            getOrderDetail();
            props.onCancelOrder();
          }}
        />
      </Modal>

      <Modal
        isOpen={ofvisible}
        className="modal"
        overlayClassName="modal-overlay"
        onRequestClose={() => setOfvisible(false)}
        style={{
          content: {
            width: "375px",
            top: "50%",
            left: "50%",
            right: "auto",
            bottom: "auto",
            marginRight: "-50%",
            transform: "translate(-50%, -50%)",
            borderRadius: "5px!important",
          },
        }}
        contentLabel="Order Feedback Modal"
      >
        <OrderFeedback
          ofvisible={ofvisible}
          setOfvisible={setOfvisible}
          orderId={orderData.id}
          onSubmit={getOrderDetail}
        />
      </Modal>

      <Modal
        isOpen={omvisible}
        className="modal modalRight"
        overlayClassName="modal-overlay"
        onRequestClose={() => setOmvisible(false)}
        style={{
          content: {
            left: "auto",
            right: "0",
            top: "0",
            bottom: "0",
            width: "450px",
            padding: "20px 0 0",
          },
        }}
        contentLabel="Order message Modal"
      >
        <OrderMessages
          omvisible={omvisible}
          setOmvisible={setOmvisible}
          orderId={orderData.id}
          data={orderMessages}
          onSubmit={(data) => {
            console.log([...orderMessages, data]);
            setOrderMessages([...orderMessages, data]);
          }}
        />
      </Modal>
    </React.Fragment>
  );
}
