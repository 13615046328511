import React, { useContext } from "react";
import GText from "../Elements/GText";
import GView from "../Elements/GView";
import { SettingsContext } from "../DataFactory/useSettings";

export default function HostYourSpace() {
  const { settings } = useContext(SettingsContext);
  return (
    <div className="main-content">
      <GText g2="true" bold text={"Become a Host"} />
      <GView
        style={{
          boxShadow: "0 0 10px #cccccc85",
          padding: "20px",
          margin: "20px 0",
        }}
      >
        <div
          dangerouslySetInnerHTML={{ __html: settings?.host_your_space }}
        ></div>
      </GView>
      <br />
    </div>
  );
}
