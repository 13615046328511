import React, { useState, useContext } from "react";
import styled from "styled-components";
import { ImgUrl, addDefaultSrc } from "../services";
import GText from "./GText";
import GView from "./GView";
import Modal from "react-modal";
import ForgotPassword from "../forms/forgotPassword";
import SignUp from "../forms/signup";
import SignIn from "../forms/login";
import { ReactComponent as FavoriteFilledIcon } from "../assets/images/favourite-filled.svg";
import { ApiService } from "../services";
import { useSnackbar } from "notistack";

const customStyles = {
  content: {
    width: "400px",
  },
};

const StoreBox = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  cursor: pointer;
  box-shadow: -1px -1px 10px #bbbbbb5c;
  padding: 10px;
  img {
    // height: 170px;
    object-fit: cover;
    margin-bottom: 10px;
    border-radius: 5px;
    border: 1px solid #f2f2f2;
  }
  .rating {
    display: inline-block;
    position: absolute;
    left: 15px;
    top: 15px;
    p {
      margin: 2px 0 2px 8px;
      font-size: 14px;
      display: flex;
      align-items: baseline;
      svg {
        width: 12px;
        fill: #333;
      }

      &.tag {
        background: ${({ theme }) => theme.body};
        color: #fff;
        padding: 2px 8px;
        font-size: 12px;
      }
    }
  }

  span {
    position: absolute;
    right: 20px;
    top: 20px;
    svg {
      width: 20px;
      height: 20px;
    }
  }

  .closed {
    background: rgba(0, 0, 0, 0.6);
    font-size: 18px;
    text-transform: uppercase;
    color: #fff;
    position: absolute;
    top: 1px;
    left: 1px;
    text-align: center;
    padding-top: 75px;
    margin: 10px;
    width: calc(100% - 20px);
    z-index: 1;
    height: calc(170px - 75px);
    font-weight: 600;
  }
`;

export default function GItemListView(props) {
  const { enqueueSnackbar } = useSnackbar();
  const [itemData] = useState(props.data);
  const [favourite, setFavourite] = useState(itemData.favourite || false);
  const [fpvisible, setFpvisible] = React.useState(false);
  const [signupvisible, setSignupvisible] = React.useState(false);
  const [signinvisible, setSigninvisible] = useState(false);

  const addToFavourite = () => {
    if (!props.settings?.user?.id) {
      setSigninvisible(true);
      return;
    }
    ApiService({
      method: "POST",
      route: "c/wishlist",
      body: { product_id: props.data.id },
    }).then((response) => {
      console.log(response.data);
      if (response.data.status_code === 1) {
        setFavourite(!favourite);
      } else {
        enqueueSnackbar(response.data.message);
      }
    });
  };
  const removeFavourite = () => {
    ApiService({
      method: "DELETE",
      route: "c/wishlist",
      body: { product_id: props.data.id },
    }).then((response) => {
      console.log(response.data);
      if (response.data.status_code === 1) {
        setFavourite(!favourite);
      } else {
        enqueueSnackbar(response.data.message);
      }
    });
  };

  return (
    <>
      <StoreBox
        style={{ cursor: props.data?.status === 0 ? "auto" : "pointer" }}
      >
        <GView style={{ position: "relative" }}>
          {props.settings?.product == "vehicle" && (
            <GText
              g6
              text={props.data?.header}
              style={{
                background: "#f5f5f5",
                width: "auto",
                position: "absolute",
                padding: "2px 10px",
                top: "0",
                right: "0",
              }}
            />
          )}
          <img
            src={
              props.data?.images?.length > 0
                ? ImgUrl("item") + "/" + props.data?.images[0]?.photo
                : ""
            }
            alt={props.data?.title}
            onError={(e) => addDefaultSrc(e, "item")}
            onClick={() => {
              if (itemData?.status === 0) {
                return;
              }
              props.onSelectItem(itemData);
            }}
            width="auto"
            height={props.settings?.product == "vehicle" ? "190px" : "170px"}
            style={{ width: "100%" }}
          />
        </GView>
        <div
          className="info"
          onClick={() => {
            if (itemData?.status === 0) {
              return;
            }
            props.onSelectItem(itemData);
          }}
        >
          <div className="rating">
            {props.data?.rating?.count == 0 ? (
              <p className="tag">{props.data?.rating?.display}</p>
            ) : (
              props.settings?.product != "vehicle" && (
                <p onClick={() => props.onShowReview(itemData)}>
                  ★ {props.data?.rating?.display}({props.data?.rating?.count})
                </p>
              )
            )}
          </div>

          <span style={{ margin: "0 5px " }}>
            {favourite === true ? (
              <FavoriteFilledIcon
                style={{ cursor: "pointer", fill: "red" }}
                onClick={(e) => {
                  e.stopPropagation();
                  removeFavourite();
                }}
              />
            ) : (
              <FavoriteFilledIcon
                style={{ cursor: "pointer", fill: "#fff" }}
                onClick={(e) => {
                  e.stopPropagation();
                  addToFavourite();
                }}
              />
            )}
          </span>

          <GText
            g4
            semi
            text={props.data?.custom_fields?.title || props.data?.title}
            style={{
              overflow: "hidden",
              textOverflow: "ellipsis",
              display: "-webkit-box",
              WebkitLineClamp: "2",
              WebkitBoxOrient: "vertical",
              // whiteSpace: "nowrap",
              padding: "0 5px",
              fontSize: "17px",
              lineHeight: "22px",
              letterSpacing: "0.3px",
            }}
          />
          <GText
            semi
            text={props.data?.custom_fields?.subtitle || props.data?.subtitle}
            style={{
              overflow: "hidden",
              textOverflow: "ellipsis",
              display: "-webkit-box",
              WebkitLineClamp: "2",
              WebkitBoxOrient: "vertical",
              // whiteSpace: "nowrap",
              padding: "2px 5px",
              fontSize: "15px",
              lineHeight: "22px",
              letterSpacing: "0.3px",
            }}
          />

          <GText
            text={props.data?.custom_fields?.address || props.data?.address}
            style={{
              overflow: "hidden",
              textOverflow: "ellipsis",
              display: "-webkit-box",
              WebkitLineClamp: "2",
              WebkitBoxOrient: "vertical",
              // whiteSpace: "nowrap",
              padding: "0 5px",
              fontSize: "15px",
              lineHeight: "22px",
              letterSpacing: "0.3px",
            }}
          />

          <GText
            med
            text={props.data?.custom_fields?.desc}
            style={{
              margin: "3px 0",
              overflow: "hidden",
              textOverflow: "ellipsis",
              whiteSpace: "nowrap",
              padding: "0 5px",
              fontSize: "14px",
            }}
          />
          {/* data?.cost */}
          {/* {props.settings?.time_charges != 0 && (
            <GText
              text={props.settings?.currency_symbol + props.data?.price}
              semi
              style={{
                margin: "5px 0",
                overflow: "hidden",
                textOverflow: "ellipsis",
                whiteSpace: "nowrap",
                padding: "0 5px",
              }}
            />
          )} */}
        </div>

        {props.data?.status === 0 ? <div className="closed">Closed</div> : ""}
      </StoreBox>

      <Modal
        isOpen={signinvisible}
        className="modal modalRight"
        overlayClassName="modal-overlay"
        onRequestClose={() => setSigninvisible(false)}
        style={customStyles}
        contentLabel="Signin Modal"
      >
        <SignIn
          signinvisible={signinvisible}
          setSigninvisible={setSigninvisible}
          setSignupvisible={setSignupvisible}
          setFpvisible={setFpvisible}
          settings={props.settings}
        />
      </Modal>

      <Modal
        isOpen={signupvisible}
        className="modal modalRight"
        overlayClassName="modal-overlay"
        onRequestClose={() => setSignupvisible(false)}
        style={customStyles}
        contentLabel="Signup Modal"
      >
        <SignUp
          signupvisible={signupvisible}
          setSignupvisible={setSignupvisible}
          setSigninvisible={setSigninvisible}
          settings={props.settings}
        />
      </Modal>

      <Modal
        isOpen={fpvisible}
        className="modal modalRight"
        overlayClassName="modal-overlay"
        onRequestClose={() => setFpvisible(false)}
        style={customStyles}
        contentLabel="Forgot Password Modal"
      >
        <ForgotPassword
          fpvisible={fpvisible}
          setFpvisible={setFpvisible}
          setSigninvisible={setSigninvisible}
          settings={props.settings}
        />
      </Modal>
    </>
  );
}
