import React, { useEffect, useState } from "react";

const UserLocContext = React.createContext(); // line A - creating the context

const UserDefaultLocation = ({ children }) => {
  const [userLocation, setUserLocation] = useState({}); // line B - setting the initial cart

  const updateLocation = (location) => {
    setUserLocation(location);
    localStorage.setItem("location", JSON.stringify(location));
  }; // line C - changing the cart

  useEffect(() => {
    let locCoordinates = localStorage.getItem("location");

    if (!locCoordinates) {
      locCoordinates = '{"lat":32.7766642,"lng":-96.79698789999999,"address":"Dallas, TX, USA"}';
    }

    console.log(locCoordinates);
    locCoordinates = locCoordinates
      ? JSON.parse(locCoordinates)
      : locCoordinates;
    // console.log(locCoordinates);
    setUserLocation(locCoordinates);
  }, []);

  return (
    <UserLocContext.Provider value={{ updateLocation, userLocation }}>
      {children}
    </UserLocContext.Provider>
  );
};

export { UserDefaultLocation, UserLocContext };
