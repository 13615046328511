import axios from "axios";
import moment from "moment-timezone";
import Axios from "./Axios";
import userPlaceholder from "./assets/images/placeholders/f-placeholder.png";
import placeholder from "./assets/images/list-placeholder.png";
require("dotenv").config();

export function ImgUrl(imgType) {
  let s = localStorage.getItem("settings");
  s = s && s != "undefined" && Object.keys(s).length > 0 ? JSON.parse(s) : s;

  var ImageUrl = process.env.REACT_APP_IMAGE_URL;

  if (imgType) {
    ImageUrl = ImageUrl + "/" + imgType;
    // ImageUrl = ImageUrl + "/taxi/" + imgType;
  }
  return ImageUrl;
}

export function addDefaultSrc(e, type) {
  e.target.src = type === "user" ? userPlaceholder : placeholder;
}

export async function getIOSVersion() {
  var match = window.navigator.appVersion.match(/OS (\d+)_(\d+)_?(\d+)?/);
  console.log(match);
  return match ? match[1] : undefined;
}

export function getDomainId() {
  const url = window.location.href;
  let domain = new URL(url);
  domain = domain.hostname;
  console.log(domain);

  if (process.env.REACT_APP_MODE === "production") {
    domain = domain.replace("www.", "");
    return domain;
  } else {
    return "vehicle";
  }
}

export async function ApiService(service) {
  let postData = service.method === "GET" ? "" : service.body;

  let url = service.route;
  let body = service.body || {};

  if (service.method === "GET") {
    let param = [];
    Object.entries(body).forEach(([key, value]) =>
      param.push(`${key}=${value}`)
    );
    if (param.length > 0) {
      url = `${url}?${param.join("&")}`;
    }
  }

  try {
    let response1 = await axios({
      method: service.method,
      url: process.env.REACT_APP_API_URL + "/" + url,
      // service.route +
      // "" +
      // urlParam,
      data: postData,
      headers: {
        Accept: "application/json",
        Authorization: "Bearer " + localStorage.getItem("wAccessToken"),
        timezone: moment.tz.guess(),
      },
    });
    console.log(response1);
    if (response1.data.status_code === 417) {
      localStorage.clear();
      window.location = "/";
    }

    return response1;
  } catch (error) {
    console.log(error.response);
    return error.response;
  }
}

export async function PaymentApiService(service) {
  console.log(moment.tz.guess());
  const getParams = () => {
    var formBody = [];
    for (var property in service.body) {
      var encodedKey = encodeURIComponent(property);
      var encodedValue = encodeURIComponent(service.body[property]);
      formBody.push(encodedKey + "=" + encodedValue);
    }
    formBody = formBody.join("&");
    return "?" + formBody;
  };
  let urlParam =
    service.method === "GET"
      ? service.body && Object.keys(service.body).length > 0
        ? getParams()
        : ""
      : "";
  let postData = service.method === "GET" ? "" : service.body;

  try {
    let response1 = await axios({
      method: service.method,
      url: service.route + "" + urlParam,
      data: postData,
      headers: {
        Accept: "application/json",
        Authorization: "Bearer " + localStorage.getItem("wAccessToken"),
        timezone: moment.tz.guess(),
      },
    });
    console.log(response1);
    if (response1.data.status_code === 417) {
      localStorage.clear();
      window.location = "/";
    }

    return response1;
  } catch (error) {
    console.log(error.response);
    return error.response;
  }
}

export async function FileApiService(method, hUrl, param) {
  try {
    console.log(param);
    var fd = new FormData();
    fd.append("image", param);

    let response1 = await axios({
      method: method,
      url: process.env.REACT_APP_API_URL + "/c/upload-image?type=" + hUrl,
      data: fd,
      headers: {
        ContentType: undefined,
        Accept: "application/json",
        Authorization: "Bearer " + localStorage.getItem("wAccessToken"),
      },
    });
    return response1.data;
  } catch (error) {
    return error;
  }
}

export async function ApiLoginService(method, hUrl, param) {
  try {
    let response1 = await axios({
      method: method,
      url: process.env.REACT_APP_API_URL + "/" + hUrl,
      data: param,
      headers: {
        Accept: "application/json",
        Authorization: "",
      },
    });
    console.log(response1);
    return response1.data;
  } catch (error) {
    console.log(error);
    return error;
  }
}

export async function LogoutService(hUrl) {
  try {
    let response1 = await axios({
      method: "POST",
      url: process.env.REACT_APP_API_URL + "/" + hUrl,
      data: "",
      headers: {
        Accept: "application/json",
        Authorization: "Bearer " + localStorage.getItem("wAccessToken"),
      },
    });
    return response1.data;
  } catch (error) {
    return error;
  }
}
