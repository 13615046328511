import React, { useEffect, useState, useContext } from "react";
import GText from "../Elements/GText";
import styled from "styled-components";
import GButton from "../Elements/GButton";
import useWindowSize from "../DataFactory/useWindowSize";
import PlacesAutocomplete, {
  geocodeByAddress,
} from "react-places-autocomplete";
import { UserLocContext } from "../DataFactory/useLocation";
import { UserSearchInfoContext } from "../DataFactory/useSearchInfo";
import GView from "../Elements/GView";
import { ReactComponent as CalendarIcon } from "../assets/images/calendar.svg";
import { ReactComponent as UserIcon } from "../assets/images/user-fill.svg";
import { ReactComponent as LocationIcon } from "../assets/images/location.svg";
import { ReactComponent as RadiusIcon } from "../assets/images/radius.svg";
import { ReactComponent as AreaIcon } from "../assets/images/area.svg";
import GSelectbox from "../Elements/gSelectbox";
import guestData from "../jsonData/guestJson";
import { SettingsContext } from "../DataFactory/useSettings";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import DateTimeRangePicker from "@wojtekmaj/react-datetimerange-picker";
import moment from "moment-timezone";

const Input = styled.input`
  border: 0;
  outline: none;
  width: calc(100% - 30px);
  padding: 0 10px 5px 4px;
  font-family: Open Sans, sans-serif;
  font-size: 0.95rem;
  font-weight: 600;

  &::placeholder {
    font-weight: 500;
    font-size: 15px;
  }
`;

const SearchDiv = styled.div`
  margin-top: 10px;
  margin-bottom: auto;
  margin-left: auto;
  margin-right: auto;
  display: flex;
  align-items: center;
  border-radius: 4px;

  .innerContent {
    background: #fff;
    display: flex;
    align-items: center;
    position: relative;
    flex: 1;

    svg {
      width: 20px;
      height: 20px;
      fill: #ccc;
    }

    .react-datepicker-wrapper {
      input {
        font-family: Open Sans, sans-serif;
        border: 0;
        padding: 0 10px 5px 3px;
        font-size: 15px;
        font-weight: 600;
        outline: 0;
        box-shadow: none;
        width: 100%;
        box-sizing: border-box;

        &::placeholder {
          font-weight: 500;
        }
      }
      .react-datepicker__close-icon {
        display: none;
      }
    }
    .react-datetimerange-picker__wrapper {
      border: 0;
      font-weight: 600;
      font-family: "Open Sans", sans-serif;
      font-size: 15px;
      letter-spacing: -0.6px;
      padding: 0 10px;
    }
    .react-datetimerange-picker__inputGroup__input {
      width: 26px;
      appearance: none;
      text-indent: 1px;
    }
  }
`;

const handleFormatChange = (d) => {
  var date = ("0" + new Date(d).getDate()).slice(-2); //Current Date
  var month = ("0" + (new Date(d).getMonth() + 1)).slice(-2); //Current Month
  var year = new Date(d).getFullYear(); //Current Year
  console.log(year + "-" + month + "-" + date);
  return year + "-" + month + "-" + date;
};

export default function HomeBannerSearch(props) {
  const { settings } = useContext(SettingsContext);
  const [address, setAddress] = useState("");
  const { userLocation, updateLocation } = useContext(UserLocContext);
  const { updateInfo, searchInfo } = useContext(UserSearchInfoContext);
  const [selectedGuests, setSelectedGuests] = useState(
    searchInfo?.guests || "90"
  );
  const [selectedRadius, setSelectedRadius] = useState(
    searchInfo?.radius || ""
  );
  const [selectedAreaFootage, setSelectedAreaFootage] = useState(
    searchInfo[48] || ""
  );
  const { width } = useWindowSize();
  // const [dateRange, setDateRange] = useState([null, null]);
  const [startDate, setStartDate] = useState(null);
  const [startEndTime, setStartEndTime] = useState([
    new Date(),
    new Date(new Date().getTime() + 1 * 60 * 60 * 1000),
  ]);

  useEffect(() => {
    setAddress(userLocation?.address);
  }, [userLocation]);

  useEffect(() => {
    console.log(searchInfo);
    setSelectedGuests(searchInfo?.guests);
    setSelectedRadius(searchInfo?.radius);
    setSelectedAreaFootage(searchInfo[48] || "");
    if (settings?.product == "room") {
      if (searchInfo?.starts) {
        setStartDate(new Date(searchInfo?.starts));
        // setDateRange([
        //   new Date(searchInfo?.starts),
        //   new Date(searchInfo?.ends),
        // ]);
      }
    } else {
      if (searchInfo?.starts) {
        setStartEndTime([
          new Date(searchInfo?.starts),
          new Date(searchInfo?.ends),
        ]);
      } else {
        updateInfo({
          ...searchInfo,
          starts: moment(new Date()).format("YYYY-MM-DD HH:mm:ss"),
          ends: moment(
            new Date(new Date().getTime() + 1 * 60 * 60 * 1000)
          ).format("YYYY-MM-DD HH:mm:ss"),
          guests: selectedGuests,
        });
      }
    }
  }, [searchInfo]);

  const handleSelect = async (value) => {
    console.log(value);
    const results = await geocodeByAddress(value);
    console.log(results[0].geometry.location);
    setAddress(value);
    let location = {
      lat: results[0].geometry.location.lat(),
      lng: results[0].geometry.location.lng(),
      address: value,
    };
    console.log(location);
    updateLocation(location);
  };

  return (
    <SearchDiv
      style={Object.assign(
        {
          flexDirection: "column",
          background: "#fff",
          border: "1px solid #e2e2e2",
          padding: "10px",
          gap: "10px",
          alignItems: "start",
          position: width <= 991 ? "relative" : "absolute",
          left:
            width <= 1050 && width > 991
              ? "-10px"
              : width <= 1100 && width > 1050
              ? "-30px"
              : width > 1100
              ? "-60px"
              : "0",
          top: width > 991 ? "45px" : "0",
        },
        props.mainStyle
      )}
    >
      <GView
        className="innerContent"
        style={{
          flex: width <= 1200 ? "20%" : "10%",
          padding: "7px",
          border: "2px solid #e2e2e2",
          width: "95%",
          minWidth:
            width > 767
              ? settings?.product == "room"
                ? "auto"
                : "280px"
              : "auto",
        }}
      >
        <LocationIcon style={{ width: "22px" }} />
        <GView style={{ width: "100%" }}>
          <GText
            g6
            med
            text={"Location"}
            style={{ textAlign: "left", fontSize: "12px", paddingLeft: 5 }}
          />

          <PlacesAutocomplete
            value={address}
            onChange={setAddress}
            onSelect={handleSelect}
            googleCallbackName="mapCallbackFunc"
          >
            {({
              getInputProps,
              suggestions,
              getSuggestionItemProps,
              loading,
            }) => (
              <div style={{ width: "100%", textAlign: "left" }}>
                <Input
                  {...getInputProps({
                    placeholder: "City, landmark or address",
                  })}
                />

                {suggestions && suggestions.length > 0 ? (
                  <div
                    style={{
                      background: "#fff",
                      position: "absolute",
                      left: "0",
                      top: "58px",
                      zIndex: "8",
                      width: "100%",
                      maxWidth: "450px",
                      boxSizing: "border-box",
                      padding: "0 5px 5px",
                      border: "1px solid rgb(226, 226, 226)",
                    }}
                  >
                    <ul
                      style={{
                        listStyleType: "none",
                        margin: "0",
                        maxHeight: "220px",
                        overflow: "overlay",
                        textAlign: "left",
                        display: "block",
                        padding: "0",
                      }}
                    >
                      {suggestions.map((suggestion, ind) => {
                        const style = {
                          display: "flex",
                          backgroundColor: suggestion.active
                            ? "#e2e2e2"
                            : "#ffffff",
                          marginRight: "10px",
                          cursor: "pointer",
                          color: "#2D2F33",
                          borderBottom: "1px solid #e2e2e2",
                          alignItems: "center",
                          padding: "8px 10px",
                        };
                        return (
                          <li
                            key={ind}
                            {...getSuggestionItemProps(suggestion, { style })}
                          >
                            <LocationIcon
                              style={{
                                fontSize: "18px",
                                color: "#ccc",
                                verticalAlign: "middle",
                              }}
                            />{" "}
                            <p
                              style={{
                                margin: "0",
                                marginLeft: "10px",
                                color: "#333",
                                width: "calc(100% - 30px)",
                              }}
                            >
                              {suggestion.description}
                            </p>
                            <br />
                          </li>
                        );
                      })}
                    </ul>
                  </div>
                ) : (
                  ""
                )}
              </div>
            )}
          </PlacesAutocomplete>
        </GView>
      </GView>

      <GView style={{ display: "flex", gap: "10px", width: "100%" }}>
        <GView
          className="innerContent"
          style={{
            padding: "7px",
            border: "2px solid #e2e2e2",
            width: "90%",
            flex:
              width > 767 ? (settings?.product == "room" ? "1" : "28%") : "1",
          }}
        >
          <CalendarIcon />
          <GView style={{ width: "100%" }}>
            <GText
              g6
              med
              text={"Start Date"}
              style={{ textAlign: "left", fontSize: "12px", paddingLeft: 5 }}
            />

            {settings?.product == "room" ? (
              <DatePicker
                selectsRange={false}
                // startDate={startDate}
                // endDate={endDate}
                selected={startDate}
                minDate={new Date()}
                onChange={(update) => {
                  console.log(update);
                  setStartDate(update);
                  console.log(startDate);
                  // console.log(endDate);
                  // if (update[0] && update[1]) {
                  //   updateInfo({
                  //     ...searchInfo,
                  //     starts: handleFormatChange(update[0]),
                  //     ends: handleFormatChange(update[1]),
                  //     guests: selectedGuests,
                  //   });
                  // }
                  let sd = new Date(update);
                  let endDate = moment(
                    sd.setDate(sd.getDate() + parseInt(selectedGuests))
                  ).format("YYYY-MM-DD");
                  updateInfo({
                    ...searchInfo,
                    starts: handleFormatChange(update),
                    ends: endDate,
                    guests: selectedGuests,
                  });
                }}
                isClearable={true}
                placeholderText={"Select Activity Time"}
                dateFormat="dd MMM"
                //   showTimeSelect={settings?.product == "room" ? false : false}
              />
            ) : (
              <DateTimeRangePicker
                minDate={new Date()}
                onChange={(value) => {
                  console.log(value);
                  if (value[0] && value[1]) {
                    updateInfo({
                      ...searchInfo,
                      starts: moment(value[0]).format("YYYY-MM-DD HH:mm:ss"),
                      ends: moment(value[1]).format("YYYY-MM-DD HH:mm:ss"),
                      guests: selectedGuests,
                    });
                  }
                }}
                value={startEndTime}
                calendarIcon={null}
                clearIcon={null}
                format="dd-mm-yyyy, hh:mm a"
              />
            )}
          </GView>
        </GView>

        {settings?.multiple_units > 0 && (
          <>
            <GView
              className="innerContent"
              style={{
                border: "0",
                padding: "7px",
                width: "calc(100% - 7px)",
                boxSizing: "border-box",
                border: "2px solid #e2e2e2",
              }}
            >
              <UserIcon />
              <GView style={{ width: "100%" }}>
                <GText
                  g6
                  med
                  text={"Term"}
                  style={{
                    textAlign: "left",
                    fontSize: "12px",
                    paddingLeft: 4,
                  }}
                />
                <GSelectbox
                  placeholder="Choose"
                  defaultvalue={selectedGuests}
                  data={guestData}
                  id="guests"
                  option_key="title"
                  option_value="value"
                  onChange={(newVal) => {
                    console.log(newVal);
                    setSelectedGuests(newVal || "");
                    let startDate = new Date(searchInfo?.starts);
                    let endDate = moment(
                      startDate.setDate(startDate.getDate() + parseInt(newVal))
                    ).format("YYYY-MM-DD");
                    updateInfo({
                      ...searchInfo,
                      guests: newVal || "",
                      ends: endDate,
                    });
                  }}
                  style={{
                    color: "#555",
                    width: "100%",
                    border: "0",
                    margin: "0",
                    padding: "0 2em 4px 5px",
                    fontFamily: "Open Sans, sans-serif",
                    fontWeight: "600",
                    flex: 1,
                  }}
                  arrowStyle={{
                    position: "absolute",
                    right: "10px",
                    paddingTop: 5,
                  }}
                />
              </GView>
            </GView>
          </>
        )}
      </GView>

      <GView style={{ display: "flex", gap: "10px", width: "100%" }}>
        <GView
          className="innerContent"
          style={{
            padding: "7px",
            border: "2px solid #e2e2e2",
            width: "49%",
            boxSizing: "border-box",
            flex:
              width > 767 ? (settings?.product == "room" ? "1" : "28%") : "1",
          }}
        >
          <RadiusIcon />
          <GView style={{ width: "100%" }}>
            <GText
              g6
              med
              text={"Radius"}
              style={{
                textAlign: "left",
                fontSize: "12px",
                paddingLeft: 7,
              }}
            />
            <GSelectbox
              placeholder={"Radius"}
              defaultvalue={selectedRadius}
              data={[
                { title: "10 Miles", value: "10" },
                { title: "25 Miles", value: "25" },
                { title: "50 Miles", value: "50" },
              ]}
              id="item_radius_filter"
              option_key="title"
              option_value="value"
              onChange={(newVal) => {
                console.log(newVal);
                setSelectedRadius(newVal || "");
                updateInfo({
                  ...searchInfo,
                  radius: newVal || "",
                });
              }}
              style={{
                color: "#555",
                width: "100%",
                border: "0",
                margin: "0",
                marginLeft: "-5px",
                padding: "0 1.5em 4px 0",
                fontFamily: "Open Sans, sans-serif",
                fontWeight: "600",
              }}
            />
          </GView>
        </GView>
        <GView
          className="innerContent"
          style={{
            padding: "7px",
            border: "2px solid #e2e2e2",
            width: "49%",
            boxSizing: "border-box",
            flex: width > 767 ? "1" : "1",
          }}
        >
          <AreaIcon />
          <GView style={{ width: "100%" }}>
            <GText
              g6
              med
              text={"Area Footage"}
              style={{
                textAlign: "left",
                fontSize: "12px",
                paddingLeft: 7,
              }}
            />
            <GSelectbox
              placeholder={"Area Footage"}
              defaultvalue={selectedAreaFootage}
              data={[
                {
                  title: "Up to 5k",
                  value: "Up to 5k",
                  selected: false,
                },
                {
                  title: "5k - 10k",
                  value: "5k - 10k",
                  selected: false,
                },
                {
                  title: "above 10k",
                  value: "above 10k",
                  selected: false,
                },
              ]}
              id="item_area_filter"
              option_key="title"
              option_value="value"
              onChange={(newVal) => {
                console.log(newVal);
                setSelectedAreaFootage(newVal || "");
                updateInfo({
                  ...searchInfo,
                  48: newVal || "",
                });
              }}
              style={{
                color: "#555",
                width: "100%",
                border: "0",
                margin: "0",
                marginLeft: "-5px",
                padding: "0 1.5em 4px 0",
                fontFamily: "Open Sans, sans-serif",
                fontWeight: "600",
              }}
            />
          </GView>
        </GView>
      </GView>
      <GButton
        variant="condensed"
        disabled={
          settings?.multiple_units > 0
            ? !userLocation?.lat || !searchInfo?.starts || !selectedGuests
            : !userLocation?.lat || !searchInfo?.starts
        }
        style={{
          alignSelf: width > 767 ? "stretch" : "auto",
          borderTopRightRadius: width > 767 ? "4px" : "0",
          borderBottomRightRadius: width > 767 ? "4px" : "0",
          padding: width <= 1200 ? "0.6em 1.5em" : "0.6em 2.5em",
          width: "100%",
        }}
        onClick={() => {
          props.onSearch();
        }}
      >
        {/* Find  {settings?.product_display} */}
        Search
      </GButton>

      <GView style={{ display: "flex", justifyContent: "left", width: "100%" }}>
        {props.showAdvancedFilters == "true" ? (
          <GButton
            variant="linkable"
            children={"Hide Advanced Filters "}
            style={{
              width: "auto",
              marginRight: "10px",
              fontSize: "13px",
              fontStyle: "italic",
              textDecoration: "underline",
            }}
            onClick={() => {
              props.setShowAdvancedFilters("false");
              let fData = {};

              props.filtersData.map((f) => {
                if (f.advanced == 1) {
                  fData[f?.identifier?.toString()] = "";
                }
              });
              props.setSelectedFilters({
                ...props.selectedFilters,
                ...fData,
              });
            }}
          />
        ) : (
          <GButton
            variant="linkable"
            children={"Show Advanced Filters"}
            style={{
              width: "auto",
              marginRight: "10px",
              fontSize: "13px",
              fontStyle: "italic",
              textDecoration: "underline",
            }}
            onClick={() => {
              props.setShowAdvancedFilters("true");
            }}
          />
        )}
      </GView>
    </SearchDiv>
  );
}
