import React, { useState } from "react";
import GView from "../Elements/GView";
import { ReactComponent as ArrowBackIos } from "../assets/images/arrowBack.svg";
import GText from "../Elements/GText";
import styled from "styled-components";

const OfferDiv = styled.div`
  display: flex;
  align-items: start;
  padding: 15px 5px;
  border-bottom: 1px solid #e2e2e2;
  img {
    margin-right: 10px;
  }
`;

export default function ItemAmenities(props) {
  if (props.itemAmenitiesVisible === false) {
    return null;
  }
  return (
    <React.Fragment>
      <div className="modal-header">
        <ArrowBackIos
          className="backIcon"
          onClick={() => props.setItemAmenitiesVisible(false)}
        />
        Amenities
      </div>
      <div className="modal-body">
        <GText g4 semi text={"Available"} style={{ margin: "10px 0" }} />
        <GView>
          {props.data
            .sort(
              ({ id: previousID }, { id: currentID }) => previousID - currentID
            )
            .filter((f) => f.type == "checkbox")
            .filter((f) => f.active == true)
            .map((feature, i) => {
              return (
                <OfferDiv key={i}>
                  <img
                    src={
                      process.env.REACT_APP_API_URL +
                      "/features/" +
                      feature?.icon
                    }
                    style={{ width: "20px", marginRight: "10px" }}
                  />
                  <GView>
                    <GText text={feature?.title} />
                    {feature?.description && (
                      <GText
                        med
                        text={feature?.description}
                        style={{ fontSize: "14px" }}
                      />
                    )}
                  </GView>
                </OfferDiv>
              );
            })}
        </GView>

        <GText
          g4
          semi
          text={"Not Included"}
          style={{ margin: "20px 0 10px" }}
        />
        <GView>
          {props.data
            .filter((f) => f.type == "checkbox")
            .filter((f) => f.active == false)
            .map((feature, i) => {
              return (
                <OfferDiv key={i} style={{ opacity: "0.4" }}>
                  <img
                    src={
                      process.env.REACT_APP_API_URL +
                      "/features/" +
                      feature?.icon
                    }
                    style={{ width: "20px", marginRight: "10px" }}
                  />
                  <GText
                    text={feature?.title}
                    style={{ textDecoration: "line-through" }}
                  />
                </OfferDiv>
              );
            })}
        </GView>
      </div>
    </React.Fragment>
  );
}
