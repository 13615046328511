import React, { useState, useContext, useEffect } from "react";
import { ApiService, ImgUrl, addDefaultSrc } from "../services";
import GView from "../Elements/GView";
import { useParams, useLocation } from "react-router-dom";
import GText from "../Elements/GText";
import { useSnackbar } from "notistack";
import { SettingsContext } from "../DataFactory/useSettings";
import { UserLocContext } from "../DataFactory/useLocation";
import { UserSearchInfoContext } from "../DataFactory/useSearchInfo";
import styled from "styled-components";
import { ReactComponent as NavigateIcon } from "../assets/images/navigation.svg";
import useWindowSize from "../DataFactory/useWindowSize";
import GButton from "../Elements/GButton";
import GInfo from "../Elements/gInfo";
import GMap from "../Components/itemLocation";
import CheckoutForm from "./orderCheckout";
import Modal from "react-modal";
import ItemAmenities from "../Components/itemAmenities";
import GLightBox from "../Elements/gLightBox";
import SignUp from "../forms/signup";
import SignIn from "../forms/login";
import ForgotPassword from "../forms/forgotPassword";
import moment from "moment-timezone";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "../assets/css/slickCarousel.css";
import {
  EmailShareButton,
  FacebookShareButton,
  FacebookMessengerShareButton,
  TwitterShareButton,
  WhatsappShareButton,
  EmailIcon,
  FacebookIcon,
  FacebookMessengerIcon,
  TwitterIcon,
  WhatsappIcon,
} from "react-share";

const Div = styled.div`
  &.rating {
    p {
      margin: 2px 0;
      font-size: 14px;
      display: flex;
      align-items: baseline;
      svg {
        width: 12px;
        fill: #333;
      }

      &.tag {
        background: ${({ theme }) => theme.body};
        color: #fff;
        padding: 2px 8px;
        font-size: 12px;
      }
    }
  }
`;

const LocationDiv = styled.div`
  svg {
    fill: ${({ theme }) => theme.body};
  }
`;

const SideBar = styled.div`
  &.sticky {
    position: fixed;
    top: 100px;
    width: 25% !important;
  }
`;

const customStyles = {
  content: {
    top: "0",
    left: "auto",
    right: "0",
    bottom: "0",
    width: "400px",
    // transform: 'translate(0%, -50%)'
  },
};

const ItemDetail = () => {
  let { id } = useParams();
  const location = useLocation();
  const { settings } = useContext(SettingsContext);
  const { userLocation } = useContext(UserLocContext);
  const { searchInfo } = useContext(UserSearchInfoContext);
  const units =
    settings.multiple_units > 0
      ? [{ users: parseInt(searchInfo?.guests) }]
      : [];
  const [isLoading, setIsLoading] = useState(true);
  const [itemInfo, setItemInfo] = useState({});
  const { enqueueSnackbar } = useSnackbar();
  const { width, height } = useWindowSize();
  const [checkoutVisible, setCheckoutVisible] = useState(false);
  const [itemAmenitiesVisible, setItemAmenitiesVisible] = useState(false);
  const [selectedImgPreview, setSelectedImgPreview] = useState(false);
  const [isImgPreview, setImgPreview] = useState(false);
  const [fpvisible, setFpvisible] = useState(false);
  const [signupvisible, setSignupvisible] = useState(false);
  const [signinvisible, setSigninvisible] = useState(false);
  const dO = settings?.product_details.split(",");

  var SliderSettings = {
    dots: true,
    infinite: false,
    swipe: true,
    speed: 500,
    slidesToShow:
      width > 1500
        ? 4
        : width > 1201 && width <= 1500
        ? 4
        : width > 991 && width <= 1200
        ? 3
        : width > 767 && width <= 991
        ? 3
        : width > 530 && width <= 767
        ? 2
        : width > 430 && width <= 530
        ? 2
        : 1,
    slidesToScroll: 1,
    autoplay: true,
  };

  const unitDisplayText = () => {
    let users = units.reduce((n, { users }) => n + users, 0);
    let user_text =
      users == 1 ? settings?.guest_display : `${settings?.guest_display}s`;

    let temp_units = units.length;
    let unit_text = settings?.unit_display;

    if (settings?.multiple_unit_booking == 1) {
      return `${temp_units} ${unit_text}, ${users} ${user_text}`;
    }

    return `${users} ${user_text}`;
  };

  const footerDisplayHeader = () => {
    let res = [];
    if (itemInfo?.price > 0) {
      res = [...res, settings.currency_symbol + itemInfo?.price_display];
    }

    if (units.length > 0) {
      res = [...res, itemInfo?.plan?.duration];
    }

    return res.join(" · ");
  };

  useEffect(() => {
    if (id) {
      // let endDate = new Date(searchInfo?.starts);
      let sendData = {
        lat: userLocation?.lat,
        lng: userLocation?.lng,
        starts: searchInfo?.starts,
        ends: searchInfo?.ends,
        // ends: moment(
        //   endDate.setDate(endDate.getDate() + parseInt(searchInfo?.guests))
        // ).format("YYYY-MM-DD"),
        units:
          settings.multiple_units == 0
            ? []
            : [
                {
                  users: searchInfo?.guests,
                  product_unit_id: location?.state?.unit_id,
                },
              ],
      };
      if (settings?.multiple_units <= "0") {
        delete sendData.units;
      }
      ApiService({
        method: "POST",
        route: "c/products/" + id,
        body: sendData,
      })
        .then((response) => {
          console.log(response);

          if (response.status === 26) {
            enqueueSnackbar(
              "NETWORK FAILED. Please check your internet connection."
            );
            return;
          } else {
            if (response?.data?.status_code === 1) {
              setItemInfo(response.data.data);
            }
          }

          setIsLoading(false);
        })
        .catch((error) => {
          console.log(error);
        });
    }
  }, [userLocation, searchInfo, id]);

  function myFunction() {
    var productSection = document.getElementById("productView");
    var sidebar = document.getElementById("sidebar");
    var stickyBottom = productSection?.clientHeight + 100;
    var stickyTop = productSection?.offsetTop - 100;
    var bottom = height;
    bottom = stickyBottom - bottom + 150;

    if (sidebar && (stickyTop || stickyBottom)) {
      if (stickyTop < window.pageYOffset && bottom > window.pageYOffset) {
        sidebar.classList.add("sticky");
      } else {
        sidebar.classList.remove("sticky");
      }
    }
  }
  useEffect(() => {
    if (width > 767) {
      window.addEventListener("scroll", myFunction);
    }
  }, [width]);

  return (
    <GView
      className="main-content"
      style={{ padding: width > 767 ? "20px 0" : "10px 20px" }}
    >
      {isLoading == true ? (
        <></>
      ) : (
        <GView>
          <GView id="productView">
            <GView
              style={{
                width: width > 767 ? "70%" : "100%",
                boxSizing: "border-box",
                paddingRight: width > 767 ? "20px" : "0",
                display: "inline-block",
              }}
            >
              <GText
                g3
                semi
                text={itemInfo?.custom_fields?.title || itemInfo?.title}
              />

              <GView
                style={{
                  display: "flex",
                  margin: "5px 0",
                  flexDirection: "row",
                }}
              >
                <GText
                  med
                  text={`${itemInfo?.custom_fields?.desc} • ${itemInfo?.address}`}
                />
                <Div className="rating" style={{ marginLeft: "auto" }}>
                  {itemInfo?.rating?.count == 0 ? (
                    <p className="tag">{itemInfo?.rating?.display}</p>
                  ) : (
                    <p>
                      ★ &nbsp;
                      {itemInfo?.rating?.avg} • {itemInfo?.rating?.count}{" "}
                      reviews
                    </p>
                  )}
                </Div>
              </GView>
              {itemInfo?.images && itemInfo?.images.length > 0 && (
                <GView style={{ position: "relative" }}>
                  <GView
                    style={{
                      display: "flex",
                      gap: itemInfo?.images.length <= 1 ? "0" : "10px",
                      padding: "10px 0",
                    }}
                  >
                    {itemInfo?.images.slice(0, 3).map((img, i) => {
                      return (
                        <React.Fragment key={i}>
                          {i == 0 && (
                            <img
                              alt=""
                              src={`${ImgUrl("item")}/${img.photo}`}
                              onError={addDefaultSrc}
                              className="thumbnail"
                              onClick={() => {
                                setSelectedImgPreview([img.photo]);
                                setImgPreview(true);
                              }}
                              style={{
                                cursor: "pointer",
                                objectFit: "cover",
                                width:
                                  itemInfo?.images.length == 1
                                    ? "100%"
                                    : itemInfo?.images.length == 2
                                    ? "50%"
                                    : itemInfo?.images.length == 3
                                    ? width > 1200
                                      ? "65%"
                                      : "66.5%"
                                    : width > 1200
                                    ? "65%"
                                    : "66.5%",
                                maxHeight:
                                  itemInfo?.images.length >= 3
                                    ? width > 1200
                                      ? "410px"
                                      : width > 991 && width <= 1200
                                      ? "360px"
                                      : "auto"
                                    : itemInfo?.images.length == 1
                                    ? "300px"
                                    : "auto",
                              }}
                            />
                          )}
                        </React.Fragment>
                      );
                    })}

                    <GView
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        gap: "10px",
                        flex: "1",
                      }}
                    >
                      {itemInfo?.images.slice(0, 3).map((img, i) => {
                        return (
                          <React.Fragment key={i}>
                            {i > 0 && (
                              <img
                                alt=""
                                src={`${ImgUrl("item")}/${img.photo}`}
                                onError={addDefaultSrc}
                                className="thumbnail"
                                style={{
                                  cursor: "pointer",
                                  width: "100%",
                                  maxHeight:
                                    itemInfo?.images.length >= "3"
                                      ? width > 1200
                                        ? "200px"
                                        : width > 991 && width <= 1200
                                        ? "175px"
                                        : "auto"
                                      : "auto",
                                  objectFit: "cover",
                                }}
                                onClick={() => {
                                  setSelectedImgPreview([img.photo]);
                                  setImgPreview(true);
                                }}
                              />
                            )}
                          </React.Fragment>
                        );
                      })}
                    </GView>
                    {itemInfo.images && itemInfo.images.length > 3 && (
                      <GButton
                        variant="linkable"
                        onClick={() => {
                          let previewImages = itemInfo.images.map((img) => {
                            return img.photo;
                          });
                          console.log(previewImages);
                          setSelectedImgPreview(previewImages);
                          setImgPreview(true);
                        }}
                        style={{
                          position: "absolute",
                          background: "#fff",
                          padding: "5px 15px",
                          borderRadius: "4px",
                          fontSize: "14px",
                          fontFamily: "Open Sans, sans-serif",
                          right: width > 767 ? "10px" : "auto",
                          left: width > 767 ? "auto" : "10px",
                          bottom: "20px",
                          color: "#000",
                        }}
                      >
                        Show all photos
                      </GButton>
                    )}
                  </GView>
                </GView>
              )}
              {dO.includes("amenities") && itemInfo?.features && (
                <>
                  {itemInfo.features
                    .filter((f) => f.type == "textarea" && f.value != "")
                    .map((feature, i) => {
                      return (
                        <React.Fragment key={i}>
                          <GText
                            g4
                            semi
                            text={feature.title}
                            style={{ margin: "20px 0" }}
                          />
                          <GText text={feature?.value} />
                        </React.Fragment>
                      );
                    })}
                </>
              )}

              {/* ============================ Item Features ============================== */}
              {itemInfo?.features &&
                itemInfo.features.filter((f) => f.type == "checkbox").length >
                  0 && (
                  <>
                    <hr
                      style={{
                        margin: "25px 0",
                        border: "0",
                        borderBottom: "1px solid #eee",
                      }}
                    />

                    <GText
                      g4
                      semi
                      text={"What this place offers"}
                      style={{ margin: "20px 0" }}
                    />

                    <GView
                      style={{
                        display: "grid",
                        gridTemplateColumns:
                          width <= 350
                            ? "repeat(auto-fit, minmax(300px, 1fr))"
                            : "repeat(auto-fit, minmax(45%, 1fr))",
                        gridGap: "20px",
                        padding: "10px 0",
                      }}
                    >
                      {itemInfo.features
                        .filter((f) => f.type == "checkbox")
                        .filter((f) => f.active == true)
                        .slice(0, 8)
                        .map((feature, i) => {
                          return (
                            <GView
                              key={i}
                              style={{ display: "flex", alignItems: "center" }}
                            >
                              <img
                                src={`${
                                  process.env.REACT_APP_API_URL
                                }/features/${feature?.icon || "plus.png"}`}
                                style={{ width: "20px", marginRight: "10px" }}
                              />
                              <GText text={feature?.title} />
                            </GView>
                          );
                        })}
                    </GView>
                    <GButton
                      variant="outlined"
                      children={"Show all amenities"}
                      onClick={() => {
                        setItemAmenitiesVisible(true);
                      }}
                      style={{
                        margin: "20px 0 10px",
                        borderRadius: "5px",
                        color: "#000",
                        border: "1px solid #000",
                      }}
                    />
                  </>
                )}

              {/* ============================ Item Features ============================== */}
              {itemInfo?.features &&
                itemInfo?.features?.length > 0 &&
                dO.includes("additional") && (
                  <>
                    {/* ============================ Item Location ============================== */}

                    <>
                      <hr
                        style={{
                          margin: "20px 0",
                          border: "0",
                          borderBottom: "1px solid #eee",
                        }}
                      />
                      <GText
                        g4
                        semi
                        text={"Property Location"}
                        style={{ margin: "20px 0" }}
                      />

                      <LocationDiv>
                        <GMap
                          lat={itemInfo?.lat}
                          lng={itemInfo?.lng}
                          address={itemInfo?.address}
                        />
                        <GView
                          style={{
                            display: "flex",
                            alignItems: "center",
                            padding: "10px 0 0",
                          }}
                        >
                          <GText
                            text={itemInfo?.address}
                            style={{
                              padding: "10px 5px",
                              flex: "1",
                            }}
                          />
                          <span
                            onClick={() => {
                              window.open(
                                `https://maps.google.com?q=${itemInfo?.lat},${itemInfo?.lng}`
                              );
                            }}
                            style={{
                              display: "inline-flex",
                              height: "100%",
                              alignItems: "center",
                              color: "#1a73e8",
                              cursor: "pointer",
                              padding: "0 5px",
                            }}
                          >
                            <NavigateIcon
                              style={{
                                width: "30px",
                                height: "30px",
                                fill: ({ theme }) => theme.body,
                              }}
                            />
                          </span>
                        </GView>
                      </LocationDiv>
                    </>

                    <hr
                      style={{
                        margin: "25px 0",
                        border: "0",
                        borderBottom: "1px solid #eee",
                      }}
                    />

                    <GText
                      g4
                      semi
                      text={"Facility Facts"}
                      style={{ margin: "20px 0" }}
                    />

                    {itemInfo.features
                      .filter((c) => c.is_fact == 1 && c?.value)
                      .map((feature, i) => {
                        return (
                          <GView
                            key={i}
                            style={{
                              display: "flex",
                              alignItems: "start",
                              padding: "7px 0",
                            }}
                          >
                            <img
                              src={`${
                                process.env.REACT_APP_API_IMAGE_URL
                              }/features/${feature?.icon || "plus.png"}`}
                              style={{
                                width: "18px",
                                marginRight: "15px",
                                paddingTop: "5px",
                              }}
                            />
                            <GView style={{ marginBottom: "5px" }}>
                              <GText
                                semib
                                text={feature?.title}
                                style={{ marginBottom: "3px" }}
                              />
                              <GText text={feature?.value} />
                            </GView>
                          </GView>
                        );
                      })}
                  </>
                )}

              {/* ============================ Item Reviews ============================== */}
              {itemInfo?.reviews &&
                dO.includes("reviews") &&
                itemInfo?.reviews.length > 0 && (
                  <>
                    <hr
                      style={{
                        margin: "20px 0 25px",
                        border: "0",
                        borderBottom: "1px solid #eee",
                      }}
                    />

                    <GText
                      g3
                      semi
                      text={`★ ${itemInfo?.rating?.avg} • ${itemInfo?.rating?.count} Reviews`}
                      style={{ margin: "20px 0" }}
                    />

                    {itemInfo?.reviews.map((r, i) => {
                      return (
                        <GView key={i} style={{ margin: "30px 10px" }}>
                          <GInfo
                            title={r?.customer?.title}
                            subtitle={r?.created_short}
                            photo={r?.customer?.thumb_photo}
                            imgType="user"
                            subtitleStyle={{
                              fontSize: "13px",
                              lineHeight: "17px",
                              color: "#555",
                            }}
                            titleStyle={{ fontSize: "16.5px" }}
                            width="40px"
                            height="40px"
                          />
                          <GText
                            text={r.review}
                            style={{
                              padding: "10px 0",
                              fontSize: "14px",
                              lineHeight: "19px",
                            }}
                          />
                        </GView>
                      );
                    })}
                  </>
                )}

              {/* ============================ Item Attachments Info ============================== */}
              {itemInfo?.attachments && itemInfo.attachments?.length > 0 && (
                <GView style={{ paddingBottom: "15px" }}>
                  <hr
                    style={{
                      margin: "20px 0",
                      border: "0",
                      borderBottom: "1px solid #eee",
                    }}
                  />
                  <GText
                    g4
                    semi
                    text={"Attachments"}
                    style={{ margin: "20px 0" }}
                  />
                  <Slider {...SliderSettings}>
                    {itemInfo?.attachments.map((a) => (
                      <GView>
                        <GView
                          style={{
                            padding: "5px 10px",
                          }}
                        >
                          <img
                            src={ImgUrl("attachments") + "/" + a.path}
                            alt={a.title}
                            style={{
                              width: "100%",
                              height: "auto",
                              borderRadius: "5px",
                            }}
                          />
                          <GText
                            semib
                            text={a.title}
                            style={{
                              margin: "5px 0",
                              fontSize: "16px",
                              color: settings?.web?.web_color,
                              textDecoration: "underline",
                              cursor: "pointer",
                            }}
                            onClick={() =>
                              window
                                .open(
                                  `${process.env.REACT_APP_IMAGE_URL}/attachments/${a.path}`,
                                  "_blank"
                                )
                                .focus()
                            }
                          />
                        </GView>
                      </GView>
                    ))}
                  </Slider>
                </GView>
              )}

              {/* ============================ Item Transportation Info ============================== */}
              {itemInfo?.transportation &&
                itemInfo.transportation?.length > 0 && (
                  <>
                    <hr
                      style={{
                        margin: "20px 0",
                        border: "0",
                        borderBottom: "1px solid #eee",
                      }}
                    />
                    <GText
                      g4
                      semi
                      text={"TRANSPORTATION"}
                      style={{ margin: "20px 0" }}
                    />

                    {itemInfo?.transportation.map((a) => (
                      <>
                        <GText g4 text={a?.title} style={{ marginTop: 15 }} />
                        {a?.options?.map((o) => (
                          <TableRow data={o} />
                        ))}
                      </>
                    ))}
                  </>
                )}

              {/* ============================ Item Host Info ============================== */}
              {dO.includes("host") && (
                <>
                  <hr
                    style={{
                      margin: "30px 0",
                      border: "0",
                      borderBottom: "0px solid #eee",
                    }}
                  />

                  <GInfo
                    title={`Hosted by ${itemInfo?.host?.title}`}
                    subtitle={`joined in ${itemInfo?.host?.created}`}
                    photo={itemInfo?.host?.thumb_photo}
                    imgType="host"
                    style={{ marginBottom: "10px" }}
                    subtitleStyle={{
                      fontSize: "13px",
                      lineHeight: "17px",
                      color: "#555",
                      padding: "3px 0",
                    }}
                    width="55px"
                    height="55px"
                    imgStyle={{
                      height: "55px",
                      width: "55px",
                      objectFit: "cover",
                    }}
                  />

                  <GView
                    style={{
                      display: "flex",
                      alignItems: "center",
                      margin: "3px 0",
                    }}
                  >
                    ★ &nbsp;
                    <GText
                      text={`${itemInfo?.host?.rating?.count} reviews`}
                      style={{ fontSize: "14px" }}
                    />
                  </GView>
                  <GView
                    style={{
                      display: "flex",
                      alignItems: "center",
                      margin: "3px 0",
                    }}
                  >
                    ✓ &nbsp;
                    <GText
                      text={"Identity Verified"}
                      style={{ fontSize: "14px" }}
                    />
                  </GView>

                  <GText
                    text={itemInfo?.host?.description}
                    style={{ padding: "10px 0", fontSize: "14px" }}
                  />
                </>
              )}
            </GView>

            <SideBar
              style={{
                width: width > 767 ? "30%" : "100%",
                boxSizing: "border-box",
                verticalAlign: "top",
                display: "inline-block",
              }}
              id="sidebar"
            >
              <GView
                style={{
                  margin: "30px 15px",
                  boxShadow: "rgb(187 187 187 / 36%) -1px -1px 10px",
                  padding: "25px 20px",
                  borderRadius: "10px",
                }}
              >
                <GText g5 semi text={footerDisplayHeader()} />
                {/* <GText
                  text={
                    units?.length == 0
                      ? itemInfo?.plan?.duration
                      : unitDisplayText()
                  }
                /> */}

                <GView
                  style={{
                    border: "1px solid #e2e2e2",
                    padding: "10px 15px",
                    margin: "15px 0 20px",
                  }}
                >
                  <GText
                    g5
                    semi
                    text={
                      settings?.multiple_units == "0"
                        ? "Plan Dates"
                        : "Availability"
                    }
                  />
                  <GView style={{ padding: "5px 0" }}>
                    <GText text={itemInfo?.plan?.starts} />
                    <GText text={itemInfo?.plan?.ends} />
                  </GView>
                  <GText
                    g6
                    med
                    text={`Duration: ${itemInfo?.plan?.duration}`}
                  />
                </GView>

                {/* {settings?.multiple_units > "0" && (
                  <GView
                    style={{
                      border: "1px solid #e2e2e2",
                      padding: "10px 15px",
                      margin: "15px 0 20px",
                    }}
                  >
                    <GText g5 semi text={settings?.guest_display} />
                    <GView style={{ padding: "5px 0" }}>
                      <GText
                        text={`${searchInfo?.guests} ${settings?.guest_display}`}
                      />
                    </GView>
                  </GView>
                )} */}

                <GButton
                  variant="condensed"
                  children={
                    settings?.multiple_units > 0
                      ? "Reserve"
                      : "Proceed to checkout"
                  }
                  onClick={() => {
                    if (settings?.user?.id) {
                      setCheckoutVisible(true);
                    } else {
                      setSigninvisible(true);
                    }
                  }}
                  style={{
                    width: "100%",
                    borderRadius: "5px",
                    fontSize: "15px",
                    fontFamily: "Open Sans, sans-serif",
                    fontWeight: 500,
                  }}
                ></GButton>
              </GView>
            </SideBar>
          </GView>

          <GView style={{ display: "flex", gap: "10px", marginTop: "20px" }}>
            <EmailShareButton url={window.location.href}>
              <EmailIcon size={32} round />
            </EmailShareButton>
            <WhatsappShareButton url={window.location.href}>
              <WhatsappIcon size={32} round />
            </WhatsappShareButton>
            <FacebookMessengerShareButton url={window.location.href}>
              <FacebookMessengerIcon size={32} round />
            </FacebookMessengerShareButton>
            <FacebookShareButton
              url={window.location.href}
              className="Demo__some-network__share-button"
            >
              <FacebookIcon size={32} round />
            </FacebookShareButton>
            <TwitterShareButton url={window.location.href}>
              <TwitterIcon size={32} round />
            </TwitterShareButton>
          </GView>
        </GView>
      )}

      {isImgPreview === true && (
        <GLightBox
          images={selectedImgPreview}
          isImgPreview={isImgPreview}
          setImgPreview={setImgPreview}
          imgType="item"
        ></GLightBox>
      )}

      <Modal
        isOpen={checkoutVisible}
        className="modal modalRight"
        overlayClassName="modal-overlay"
        onRequestClose={() => setCheckoutVisible(false)}
        style={{
          content: {
            top: "0",
            left: "auto",
            right: "0",
            bottom: "0",
            maxWidth: "450px",
            width: "100%",
          },
        }}
        contentLabel="Checkout Modal"
      >
        <CheckoutForm
          checkoutVisible={checkoutVisible}
          setCheckoutVisible={setCheckoutVisible}
          data={{
            product_id: itemInfo?.id,
            lat: userLocation?.lat,
            lng: userLocation?.lng,
            starts: searchInfo?.starts,
            ends: searchInfo?.ends,
            units: [
              {
                users: searchInfo?.guests,
                product_unit_id: location?.state?.unit_id,
              },
            ],
            coupon_code: "",
            points: "",
            tip: "",
            gateway: {},
          }}
        />
      </Modal>

      <Modal
        isOpen={itemAmenitiesVisible}
        className="modal modalRight"
        overlayClassName="modal-overlay"
        onRequestClose={() => setItemAmenitiesVisible(false)}
        style={{
          content: {
            top: "0",
            left: "auto",
            right: "0",
            bottom: "0",
            maxWidth: "420px",
            width: "100%",
          },
        }}
        contentLabel="Item Amenities Modal"
      >
        <ItemAmenities
          itemAmenitiesVisible={itemAmenitiesVisible}
          setItemAmenitiesVisible={setItemAmenitiesVisible}
          data={itemInfo.features}
        />
      </Modal>

      <Modal
        isOpen={signinvisible}
        className="modal modalRight"
        overlayClassName="modal-overlay"
        onRequestClose={() => setSigninvisible(false)}
        style={customStyles}
        contentLabel="Login Modal"
      >
        <SignIn
          signinvisible={signinvisible}
          setSigninvisible={setSigninvisible}
          setSignupvisible={setSignupvisible}
          setFpvisible={setFpvisible}
          settings={settings}
        />
      </Modal>

      <Modal
        isOpen={signupvisible}
        className="modal modalRight"
        overlayClassName="modal-overlay"
        onRequestClose={() => setSignupvisible(false)}
        style={customStyles}
        contentLabel="Signup Modal"
      >
        <SignUp
          signupvisible={signupvisible}
          setSignupvisible={setSignupvisible}
          setSigninvisible={setSigninvisible}
          settings={settings}
        />
      </Modal>
      <Modal
        isOpen={fpvisible}
        className="modal modalRight"
        overlayClassName="modal-overlay"
        onRequestClose={() => setFpvisible(false)}
        style={customStyles}
        contentLabel="Forgot Password Modal"
      >
        <ForgotPassword
          fpvisible={fpvisible}
          setFpvisible={setFpvisible}
          setSigninvisible={setSigninvisible}
          settings={settings}
        />
      </Modal>
    </GView>
  );
};
export default ItemDetail;

const TableRow = ({ data }) => {
  const { title, duration, distance } = data;
  return (
    <>
      <GView style={{ display: "flex", flex: 1, padding: "10px 0px " }}>
        <GText text={title} style={{ flex: 1 }} />

        <GText text={duration} style={{ minWidth: 150, textAlign: "right" }} />

        <GText text={distance} style={{ minWidth: 150, textAlign: "right" }} />
      </GView>
      <hr
        style={{
          margin: "0px 0",
          border: "0",
          borderBottom: "1px solid #eee",
        }}
      />
    </>
  );
};
