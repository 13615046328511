import React, { Component, useRef, useEffect } from "react";
import LocationIcon from "../assets/images/marker.png";
import MapStyles from "../jsonData/mapStyles.json";

const GMap = (props) => {
  const googleMapRef = useRef(null);
  let googleMap = null;
  console.log(props);
  // list of icons

  // list of the marker object along with icon
  const markerList = props?.markers || [
    {
      lat: parseFloat(props.lat),
      lng: parseFloat(props.lng),
      icon: LocationIcon,
      title: props.address,
      type: "address",
    },
  ];
  console.log(markerList);
  useEffect(() => {
    googleMap = initGoogleMap();
    var bounds = new window.google.maps.LatLngBounds();
    markerList.map((x) => {
      if (x.lat) {
        const marker = createMarker(x);
        bounds.extend(marker.position);
        if (x?.id) {
          window.google.maps.event.addListener(
            marker,
            "click",
            (function (marker, x) {
              return function () {
                console.log(x);
                props.onSelectItemLocation(x.id);
              };
            })(marker, x)
          );
        }
      }
    });
    console.log(googleMap);
    // googleMap.fitBounds(bounds); // the map to contain all markers
    googleMap.setZoom(13);
  }, []);

  // initialize the google map
  const initGoogleMap = () => {
    return new window.google.maps.Map(googleMapRef.current, {
      //   zoom: 25,
      center: {
        lat: parseFloat(props.lat),
        lng: parseFloat(props.lng),
      },
      mapTypeControl: false,
      streetViewControl: false,
      zoomControl: false,
      fullscreenControl: false,
      styles: MapStyles,
    });
  };

  // create marker on google map
  const createMarker = (markerObj) =>
    new window.google.maps.Marker({
      position: { lat: markerObj?.lat, lng: markerObj?.lng },
      map: googleMap,
      // icon: {
      //   // url: markerObj.icon,
      //   title: markerObj.title,
      //   // set marker width and height
      //   scaledSize: new window.google.maps.Size(40, 40),
      // },
      icon: markerObj.icon,
    });

  return (
    <div
      ref={googleMapRef}
      style={{
        maxWidth: "100%",
        height: props?.height || "300px",
      }}
    />
  );
};

export default GMap;
