import React, { useEffect, useState } from "react";
import ItemView from "../Elements/gItemListView";

export default function OrderProductInfo(props) {
  console.log(props);
  const [selectedProduct, setSelectedProduct] = useState(props.item[0]);

  useEffect(() => {
    setSelectedProduct(props.item[0]);
  }, [props]);

  if (props.itemInfoVisible === false) {
    return null;
  }
  return (
    <React.Fragment>
      <div style={{ padding: "0 15px" }}>
        <ItemView
          data={selectedProduct}
          settings={props.settings}
          onSelectItem={() => {
            props.onSelectItem(selectedProduct);
          }}
        />
      </div>
    </React.Fragment>
  );
}
