import React, { useContext, useState } from "react";
import { LogoutService, ImgUrl } from "../services";
import GView from "../Elements/GView";
import styled from "styled-components";
import ChangePassword from "../forms/ChangePassword";
import EditProfile from "../forms/editProfile";
import ForgotPassword from "../forms/forgotPassword";
import SignUp from "../forms/signup";
import SignIn from "../forms/login";
import { Link } from "react-router-dom";
import GAvtar from "../Elements/GAvtar";
import Modal from "react-modal";
import { ReactComponent as OfferIcon } from "../assets/images/offers.svg";
import { ReactComponent as UserIcon } from "../assets/images/user.svg";
import GConfirmDialog from "../Elements/gConfirmDialog";
import { SettingsContext } from "../DataFactory/useSettings";
import GText from "../Elements/GText";
import {
  Button,
  MenuItem,
  MenuList,
  Popper,
  Paper,
  Grow,
  ClickAwayListener,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import FavouriteProducts from "../Components/favouriteList";

const Header = styled.header`
  background: #fff;
  box-shadow: -1px -1px 10px #bbbbbb5c;
  box-sizing: border-box;
  // padding: 6px 12px;
  z-index: 9;
  position: fixed;
  top: 0;
  width: 100%;
  height: 80px;
  display: block;

  a {
    padding: 0 10px;
    text-decoration: none;
    color: #333;
    display: flex;
    svg {
      width: 20px;
      height: 20px;
      margin-right: 7px;
    }
  }
`;

const PaperTheme = styled(Paper)`
  margin-top: 15px;
  // min-width:200px;
  border-top: 3px solid ${({ theme }) => theme.body};

  // &::before {
  //   content: "";
  //   position: absolute;
  //   background: #fff;
  //   border-color: ${({ theme }) => theme.body};
  //   border: 1px solid ${({ theme }) => theme.body};
  //   border-bottom-color: transparent;
  //   border-right-color: transparent;
  //   border-width: 3px;
  //   padding: 7px;
  //   margin-left: -10px;
  //   transform: rotate(45deg);
  //   top: 8px;
  //   right: 30px;
  //   z-index: 1;
  // }

  ul {
    padding: 7px 0;
    margin: 0;
    li {
      padding: 6px 17px;
      text-decoration: none;
      color: rgb(51, 51, 51);
      cursor: pointer;
      font-size: 15px;
      line-height: 25px;

      &:hover {
        background: #e2e2e2;
        color: #000;
      }
    }
  }
`;

const HeaderView = () => {
  const navigate = useNavigate();
  const { settings } = useContext(SettingsContext);
  const [cpvisible, setCpvisible] = React.useState(false);
  const [epvisible, setEpvisible] = React.useState(false);
  const [fpvisible, setFpvisible] = React.useState(false);
  const [signupvisible, setSignupvisible] = React.useState(false);
  const [signinvisible, setSigninvisible] = useState(false);

  const [wishlistVisible, setWishlistVisible] = useState(false);
  const [dialogOpen, setDialogOpen] = useState(false);
  const [open, setOpen] = React.useState(false);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [placement, setPlacement] = React.useState();

  const handleClick = (newPlacement) => (event) => {
    setAnchorEl(event.currentTarget);
    setOpen((prev) => placement !== newPlacement || !prev);
    setPlacement(newPlacement);
  };

  const handleClose = (event) => {
    setOpen(false);
    setPlacement();
  };

  function handleListKeyDown(event) {
    if (event.key === "Tab") {
      event.preventDefault();
      setOpen(false);
    }
  }

  const customStyles = {
    content: {
      width: "400px",
    },
  };

  const handleLogout = () => {
    LogoutService("c/logout")
      .then((response) => {
        console.log(response);
        if (response.status_code === 1) {
          console.log(response);
          localStorage.setItem("wAccessToken", "");
          localStorage.setItem("wRefreshToken", "");
          localStorage.setItem("wExpiresIn", 0);
          window.location = "/";
        } else {
          console.log(response.data);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  return (
    <Header style={{ minHeight: "40px" }}>
      <div
        className="main-content"
        style={{ marginTop: "0", marginBottom: "0" }}
      >
        <GView
          style={{ display: "flex", padding: "24px 0", alignItems: "center" }}
          className="logo-header"
        >
          <Link
            to="/"
            style={{
              display: "inline-flex",
              height: "100%",
              alignItems: "center",
              textDecoration: "none",
            }}
          >
            {settings?.web?.web_logo ? (
              <img
                src={ImgUrl("web") + "/" + settings?.web?.web_logo}
                className="header-logo"
                alt={settings?.project_name}
                // onError={addDefaultLogo}
                style={{ maxWidth: "180px", width: "80%" }}
              />
            ) : (
              <GText g2="true" bold theme text={"HOME"} />
            )}
          </Link>
          <GView style={{ marginLeft: "auto" }} className="right-navbar">
            <GView
              style={{
                position: "relative",
                display: "flex",
                alignItems: "center",
              }}
            >
              <Link
                to={{
                  pathname: "#",
                  state: { title: "Click Here if Host" },
                }}
              >
                Click Here if Host
              </Link>
              <Link
                to={{
                  pathname: "/host-your-space",
                  state: { title: "Become a Host" },
                }}
              >
                Become a Host
              </Link>

              {settings ? (
                !settings?.user?.id ? (
                  <a
                    onClick={() => setSigninvisible(true)}
                    style={{ cursor: "pointer" }}
                  >
                    <UserIcon />
                    Login
                  </a>
                ) : (
                  <Button
                    aria-controls={open ? "menu-list-grow" : undefined}
                    onClick={handleClick("bottom")}
                    aria-haspopup="true"
                    className="dropdownMenu"
                    style={{ padding: "0 10px", background: "transparent" }}
                  >
                    {/* <a
                    onClick={() => setOpen(true)}
                    style={{
                      display: "flex",
                      alignItems: "center",
                      cursor: "pointer",
                    }}
                  > */}
                    <GAvtar
                      title={settings?.user?.title}
                      width={"40px"}
                      height={"40px"}
                      src={
                        settings?.user?.thumb_photo
                          ? ImgUrl("user") + "/" + settings?.user?.thumb_photo
                          : ""
                      }
                      imgType="user"
                      alt={settings?.user?.title}
                    />
                    &nbsp;&nbsp;
                    <GText
                      text={settings?.user?.title}
                      style={{
                        overflow: "hidden",
                        textOverflow: "ellipsis",
                        whiteSpace: "nowrap",
                        maxWidth: "100px",
                        textAlign: "left",
                      }}
                    />
                  </Button>
                )
              ) : (
                ""
              )}

              <Popper
                style={{ zIndex: "99" }}
                open={open}
                anchorEl={anchorEl}
                placement={placement}
                role={undefined}
                transition
              >
                {({ TransitionProps }) => {
                  return (
                    <Grow {...TransitionProps}>
                      <PaperTheme>
                        <ClickAwayListener onClickAway={handleClose}>
                          <MenuList
                            autoFocusItem={open}
                            id="menu-list-grow"
                            onKeyDown={handleListKeyDown}
                          >
                            <MenuItem
                              onClick={() => {
                                navigate(`profile/bookings`, {
                                  state: { title: "Activities" },
                                });
                                setOpen(false);
                              }}
                            >
                              Manage Profile
                            </MenuItem>

                            <MenuItem
                              onClick={() => {
                                navigate(`profile/bookings`, {
                                  state: { title: "Activities" },
                                });
                                setOpen(false);
                              }}
                            >
                              Activities
                            </MenuItem>
                            <MenuItem
                              onClick={() => {
                                setWishlistVisible(true);
                                setAnchorEl(null);
                                setOpen(false);
                              }}
                            >
                              Favourite {settings?.product_display}
                            </MenuItem>
                            <MenuItem
                              onClick={() => {
                                setCpvisible(true);
                                setAnchorEl(null);
                                setOpen(false);
                              }}
                            >
                              Change Password
                            </MenuItem>
                            <MenuItem onClick={() => setDialogOpen(true)}>
                              Logout
                            </MenuItem>
                          </MenuList>
                        </ClickAwayListener>
                      </PaperTheme>
                    </Grow>
                  );
                }}
              </Popper>

              {/* {open === true ? (
                <LinkDropdown ref={dropdownRef}>
                  <ul>
                    <li>
                      <Link
                        to={{
                          pathname: "/profile/bookings",
                          state: { title: "Bookings" },
                        }}
                        onClick={() => setOpen(false)}
                      >
                        Manage Profile
                      </Link>
                    </li>
                    <li>
                      <Link
                        to={{
                          pathname: "/profile/bookings",
                          state: { title: "Bookings" },
                        }}
                        onClick={() => setOpen(false)}
                      >
                        Bookings
                      </Link>
                    </li>
                    <li>
                      <a
                        onClick={() => {
                          setCpvisible(true);
                          setOpen(false);
                        }}
                      >
                        Change Password
                      </a>
                    </li>
                    <li>
                      <a
                        onClick={() => {
                          setOpen(false);
                          //setDialogOpen(true);
                          handleLogout();
                        }}
                      >
                        Logout
                      </a>
                    </li>
                  </ul>
                </LinkDropdown>
              ) : (
                ""
              )} */}
            </GView>
          </GView>
        </GView>
      </div>

      {dialogOpen === true && (
        <GConfirmDialog
          open={dialogOpen}
          title="Logout"
          text="Are you sure you want to Logout?"
          handleClose={() => setDialogOpen(false)}
          onConfirmClose={() => {
            setDialogOpen(false);
            handleLogout();
          }}
        ></GConfirmDialog>
      )}
      <Modal
        isOpen={signinvisible}
        className="modal modalRight"
        overlayClassName="modal-overlay"
        onRequestClose={() => setSigninvisible(false)}
        style={customStyles}
        contentLabel="Example Modal"
      >
        <SignIn
          signinvisible={signinvisible}
          setSigninvisible={setSigninvisible}
          setSignupvisible={setSignupvisible}
          setFpvisible={setFpvisible}
          settings={settings}
        />
      </Modal>

      <Modal
        isOpen={signupvisible}
        className="modal modalRight"
        overlayClassName="modal-overlay"
        onRequestClose={() => setSignupvisible(false)}
        style={customStyles}
        contentLabel="Example Modal"
      >
        <SignUp
          signupvisible={signupvisible}
          setSignupvisible={setSignupvisible}
          setSigninvisible={setSigninvisible}
          settings={settings}
        />
      </Modal>

      <Modal
        isOpen={epvisible}
        className="modal"
        overlayClassName="modal-overlay"
        onRequestClose={() => setEpvisible(false)}
        style={{
          content: {
            width: "380px",
            top: "50%",
            left: "50%",
            right: "auto",
            bottom: "auto",
            marginRight: "-50%",
            transform: "translate(-50%, -50%)",
            borderRadius: "5px!important",
          },
        }}
        contentLabel="Example Modal"
      >
        <EditProfile epvisible={epvisible} setEpvisible={setEpvisible} />
      </Modal>

      <Modal
        isOpen={cpvisible}
        className="modal"
        overlayClassName="modal-overlay"
        onRequestClose={() => setCpvisible(false)}
        style={{
          content: {
            width: "375px",
            top: "50%",
            left: "50%",
            right: "auto",
            bottom: "auto",
            marginRight: "-50%",
            transform: "translate(-50%, -50%)",
            maxHeight: "310px",
            borderRadius: "5px!important",
          },
        }}
        contentLabel="Example Modal"
      >
        <ChangePassword cpvisible={cpvisible} setCpvisible={setCpvisible} />
      </Modal>

      <Modal
        isOpen={fpvisible}
        className="modal modalRight"
        overlayClassName="modal-overlay"
        onRequestClose={() => setFpvisible(false)}
        style={customStyles}
        contentLabel="Example Modal"
      >
        <ForgotPassword
          fpvisible={fpvisible}
          setFpvisible={setFpvisible}
          setSigninvisible={setSigninvisible}
          settings={settings}
        />
      </Modal>

      <Modal
        isOpen={wishlistVisible}
        className="modal modalRight"
        overlayClassName="modal-overlay"
        onRequestClose={() => setWishlistVisible(false)}
        style={{
          content: {
            top: "0",
            left: "auto",
            right: "0",
            bottom: "0",
            width: "450px",
            padding: "20px 0",
            overflow: "hidden",
          },
        }}
        contentLabel="Favorite Products Modal"
      >
        <FavouriteProducts
          wishlistVisible={wishlistVisible}
          setWishlistVisible={setWishlistVisible}
        />
      </Modal>
    </Header>
  );
};

export default HeaderView;
