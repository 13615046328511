import React, { useState } from "react";
import { ApiService } from "../services";
import AddressView from "../Elements/gAddressView";
import AddressForm from "../forms/addressForm";
import GButton from "../Elements/GButton";
import { useSnackbar } from "notistack";
import useDataFctory from "../useDataFactory";
import Modal from "react-modal";
import GText from "../Elements/GText";
import GView from "../Elements/GView";
import useWindowSize from "../DataFactory/useWindowSize";

export default function Addresses() {
  const { width } = useWindowSize();
  const [advisible, setAdvisible] = useState(false);
  const [selectedAddress, setSelectedAddress] = useState({});
  const [isEmpty, setIsEmpty] = useState(true);
  const { enqueueSnackbar } = useSnackbar();
  const {
    loading,
    Shimmer,
    Placeholder,
    data,
    loadMore,
    pagination,
    refreshData,
  } = useDataFctory("address", true);

  const handleDelete = (addressId) => {
    ApiService({
      method: "DELETE",
      route: "c/addresses/" + addressId,
      body: "",
    })
      .then((response) => {
        console.log(JSON.stringify(response));
        if (response.data.status_code === 1) {
          refreshData();
        }
        enqueueSnackbar(response.data.message);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const handleDefaultAddress = (newVal) => {
    ApiService({
      method: "PUT",
      route: "c/addresses/" + newVal,
      body: { default: 1 },
    })
      .then((response) => {
        console.log(JSON.stringify(response));
        if (response.data.status_code === 1) {
          refreshData();
        }
        enqueueSnackbar(response.data.message);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  return (
    <React.Fragment>
      <GView
        style={{ display: "flex", margin: "10px 5px", alignItems: "center" }}
      >
        <GText g2="true" theme bold text={"Saved Address"} />
        {data.length > 0 && (
          <>
            &nbsp;
            <GText
              g5
              med
              semi
              text={` (${pagination.total} Address)`}
              style={{ fontStyle: "italic" }}
            />
          </>
        )}
        <GButton
          variant="condensed"
          children="Add New"
          onClick={() => {
            setSelectedAddress({});
            setIsEmpty(true);
            setAdvisible(true);
          }}
          style={{
            marginLeft: "auto",
          }}
        />
      </GView>

      {loading === true ? (
        <Shimmer></Shimmer>
      ) : data.length === 0 ? (
        <Placeholder />
      ) : (
        <React.Fragment>
          <GView
            style={{
              display: data.length == 1 && width > 767 ? "inline-grid" : "grid",
              gridTemplateColumns:
                width <= 350
                  ? "repeat(auto-fit, minmax(300px, 1fr))"
                  : "repeat(auto-fit, minmax(350px, 1fr))",
              gridGap: "10px",
            }}
          >
            {data.map((v, i) => {
              return (
                <AddressView
                  key={i}
                  dropdownActions={true}
                  onEdit={(sAddress) => {
                    setSelectedAddress(sAddress);
                    setIsEmpty(false);
                    setAdvisible(true);
                  }}
                  onDelete={handleDelete}
                  addressData={v}
                  onSubmit={refreshData}
                  default={true}
                  onSelectDefault={handleDefaultAddress}
                />
              );
            })}
          </GView>
          {pagination.next_page_url && (
            <GButton
              variant="condensed"
              children="Load More"
              type="button"
              onClick={() => loadMore()}
              style={{
                display: "flex",
                margin: "10px auto",
                justifyContent: "center",
              }}
            />
          )}
        </React.Fragment>
      )}

      <br />

      <Modal
        isOpen={advisible}
        className="modal modalRight"
        overlayClassName="modal-overlay"
        onRequestClose={() => setAdvisible(false)}
        style={{
          content: {
            top: "0",
            left: "auto",
            right: "0",
            bottom: "0",
            width: "500px",
            paddingBottom: "0",
          },
        }}
        contentLabel="Address Modal"
      >
        <AddressForm
          advisible={advisible}
          setAdvisible={setAdvisible}
          data={selectedAddress}
          onSubmit={refreshData}
          isEmpty={isEmpty}
        />
      </Modal>
    </React.Fragment>
  );
}
