import React, { useContext } from "react";
import ProductList from "./favouriteProducts";
import useDataFactory from "../useDataFactory";
import { SettingsContext } from "../DataFactory/useSettings";
import { ReactComponent as ArrowBackIos } from "../assets/images/arrowBack.svg";
import GText from "../Elements/GText";
import { useNavigate } from "react-router-dom";

function FavouriteProducts(props) {
  const navigate = useNavigate();
  const {
    loading,
    Shimmer,
    Placeholder,
    data,
    loadMore,
    pagination,
    refreshData,
  } = useDataFactory("wishlist", true);
  const { settings } = useContext(SettingsContext);

  if (props.wishlistVisible === false) {
    return null;
  }
  return (
    <React.Fragment>
      <div className="modal-header">
        <ArrowBackIos
          className="backIcon"
          onClick={() => props.setWishlistVisible(false)}
        />
        <GText
          g4
          semi
          text={`Favourite ${settings?.product_display}`}
          style={{ fontSize: "17px" }}
        />
      </div>
      <div
        className="modal-body"
        style={{
          padding: "0 25px",
          overflow: "overlay",
        }}
      >
        {loading === true ? (
          <Shimmer></Shimmer>
        ) : (
          <ProductList
            data={data}
            Placeholder={Placeholder}
            settings={settings}
            pagination={pagination}
            onLoadMore={() => loadMore()}
            type="favourite"
            onFavouriteRemove={() => {
              refreshData();
            }}
            onSelectItem={(item) => {
              navigate(
                `/${settings?.product_display?.toLowerCase()}/${item.id}`,
                {
                  state: {
                    unit_id:
                      item?.product_units && item?.product_units.length > 0
                        ? item?.product_units[0]?.id
                        : "",
                    title: item?.custom_fields?.title,
                  },
                }
              );
            }}
          />
        )}
      </div>
    </React.Fragment>
  );
}

export default FavouriteProducts;
