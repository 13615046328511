import React, { useContext, useState, useEffect } from "react";
import { ApiLoginService } from "../services";
import GView from "../Elements/GView";
import { Link, useLocation } from "react-router-dom";
import styled from "styled-components";
import { ReactComponent as Ios } from "../assets/images/ios-logo.svg";
import { ReactComponent as Android } from "../assets/images/android-logo.svg";
import { ReactComponent as PhoneIcon } from "../assets/images/icons/phone.svg";
import { ReactComponent as MailIcon } from "../assets/images/icons/mail.svg";
import useLocalStorage from "../DataFactory/useLocalStorage";
import { SettingsContext } from "../DataFactory/useSettings";
import GText from "../Elements/GText";
import useWindowSize from "../DataFactory/useWindowSize";

const PagesList = [
  { title: "T & C", link: "/terms" },
  { title: "Privacy Policies", link: "/privacy-policy" },
  { title: "FAQ's", link: "/faqs" },
  // { title: "Offers", link: "/offers" },
];

const Footer = styled.div`
  margin-top: 20px;
  border-top: 3px solid ${({ theme }) => theme.body};
  background: #fff;
  a {
    text-decoration: none;
    &.theme {
      color: ${({ theme }) => theme.body};
      font-weight: 600;
    }
  }
  ul {
    list-style-type: none;
    padding: 0;
    &.apps {
      display: flex;
      margin-bottom: 20px;
      li {
        padding: 0;
        a {
          margin: 5px;
          padding: 4px;
          height: 24px;
          width: 24px;
          text-align: center;
          background: ${({ theme }) => theme.body};
          border-radius: 20px;
          svg {
            fill: #fff;
            width: 17px;
            height: 17px;
            vertical-align: top;
          }
        }
      }
    }
    @media (max-width: 767px) {
      &.apps {
        justify-content: center;
      }
    }
  }
`;
export default function FooterView() {
  const { settings } = useContext(SettingsContext);
  const { width } = useWindowSize();
  const location = useLocation();
  const [socialLinks, setSocialLinks] = useLocalStorage("social", []);
  useEffect(() => {
    if (socialLinks && socialLinks.length > 0) {
      return;
    }
    ApiLoginService("GET", "c/social")
      .then((response) => {
        console.log(JSON.stringify(response));
        if (response.status_code === 1) {
          setSocialLinks(response.data);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);

  return (
    <Footer
      style={{
        marginTop: location.pathname === "/add-order" ? "0" : "20px",
        background: settings?.web?.web_footer_color || "#fff",
      }}
    >
      <div className="main-content">
        <GView
          style={{
            display: "flex",
            padding: "20px 0",
            flexDirection: width <= 767 ? "column" : "row",
          }}
        >
          <GView
            style={{
              flex: "1 1 70%",
              textAlign: width <= 767 ? "center" : "left",
            }}
          >
            {settings?.i_c_url || settings?.a_c_url ? (
              <React.Fragment>
                <GText
                  g3
                  bold
                  text={"Download Apps"}
                  style={{ margin: "15px 0" }}
                />
                <ul className="apps">
                  <li>
                    {settings?.i_c_url && (
                      <a
                        href={settings?.i_c_url}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        <Ios />
                      </a>
                    )}
                    {settings?.a_c_url && (
                      <a
                        href={settings?.a_c_url}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        <Android />
                      </a>
                    )}
                  </li>
                </ul>
              </React.Fragment>
            ) : (
              <br />
            )}
            <a
              style={{
                display: "flex",
                margin: "10px 0",
                justifyContent: width <= 767 ? "center" : "left",
              }}
              href={`mailto:${settings?.admin_email}`}
            >
              <MailIcon
                style={{ width: "20px", marginRight: "10px", fill: "#555" }}
              />
              <GText dark text={settings?.admin_email}></GText>
            </a>
            <a
              style={{
                display: "flex",
                margin: "10px 0",
                justifyContent: width <= 767 ? "center" : "left",
              }}
              href={`mailto:${settings?.admin_phone}`}
            >
              <PhoneIcon
                style={{ width: "20px", marginRight: "10px", fill: "#555" }}
              />
              <GText dark text={settings?.admin_phone}></GText>
            </a>
          </GView>
          <GView
            style={{ flex: "50%", textAlign: width <= 767 ? "center" : "left" }}
          >
            <GText g3 bold text={"Pages"} style={{ margin: "15px 0" }} />
            <ul>
              {PagesList.map((p, i) => {
                return (
                  <li key={i} style={{ padding: "5px 0" }}>
                    <Link to={p.link}>
                      <GText dark text={p.title} />
                    </Link>
                  </li>
                );
              })}
            </ul>
          </GView>
          {socialLinks && socialLinks.length > 0 ? (
            <GView
              style={{
                flex: "50%",
                textAlign: width <= 767 ? "center" : "left",
              }}
            >
              <GText g3 bold text={"Follow us"} style={{ margin: "15px 0" }} />
              <ul>
                {socialLinks.map((s, i) => {
                  return (
                    <li key={i} style={{ padding: "5px 0" }}>
                      <a href={s.url} target="_blank" rel="noopener noreferrer">
                        <GText dark text={s.title} />
                      </a>
                    </li>
                  );
                })}
              </ul>
            </GView>
          ) : (
            ""
          )}
        </GView>
        <GView
          style={{
            display: "flex",
            padding: "20px 0",
            borderTop: "1px solid #e2e2e2",
            flexDirection: width <= 767 ? "column" : "row",
            justifyContent: width <= 767 ? "center" : "",
          }}
        >
          <GText
            text={`(c) ${new Date().getFullYear()} FlexPrime. All Rights Reserved`}
            style={{
              color: "#333",
              width: "auto",
              margin: width <= 767 ? "0 auto" : "0",
            }}
          />
          <p
            style={{
              margin: width > 767 ? "0 0 0 auto" : "5px auto 0 ",
              fontSize: "14px",
              color: "#333",
            }}
          >
            Powered by{" "}
            <a rel="noopener noreferrer" className="theme">
              Code17
            </a>
          </p>
        </GView>
      </div>
    </Footer>
  );
}
