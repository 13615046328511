import React, { useEffect, useState, useContext } from "react";
import { FlatList, View } from "react-native";
import { ImgUrl, addDefaultSrc } from "../services";
import styled from "styled-components";
import GButton from "../Elements/GButton";
import { ApiService } from "../services";
import { useSnackbar } from "notistack";
import GText from "../Elements/GText";
import GView from "../Elements/GView";
import { ReactComponent as FavoriteFilledIcon } from "../assets/images/favourite-filled.svg";

const StoreBox = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  cursor: pointer;
  margin-bottom: 10px;
  img {
    // height: 170px;
    object-fit: cover;
    margin-bottom: 5px;
    border-radius: 5px;
    border: 1px solid #f2f2f2;
  }
  .rating {
    display: inline-block;
    position: absolute;
    left: 10px;
    top: 10px;
    p {
      margin: 2px 0 2px 8px;
      font-size: 14px;
      display: flex;
      align-items: baseline;
      svg {
        width: 12px;
        fill: #333;
      }

      &.tag {
        background: ${({ theme }) => theme.body};
        color: #fff;
        padding: 2px 8px;
        font-size: 12px;
      }
    }
  }

  span {
    position: absolute;
    right: 10px;
    top: 10px;
    svg {
      width: 20px;
      height: 20px;
    }
  }

  .closed {
    background: rgba(0, 0, 0, 0.6);
    font-size: 18px;
    text-transform: uppercase;
    color: #fff;
    position: absolute;
    top: 1px;
    left: 1px;
    text-align: center;
    padding-top: 75px;
    margin: 10px;
    width: calc(100% - 20px);
    z-index: 1;
    height: calc(170px - 75px);
    font-weight: 600;
  }
`;

export default function GProductStyles(props) {
  const { enqueueSnackbar } = useSnackbar();
  const [items, setItems] = useState(props.data || []);

  useEffect(() => {
    console.log(props.data);
    setItems(props.data);
  }, [props]);

  const removeFavourite = (productId) => {
    ApiService({
      method: "DELETE",
      route: "c/wishlist",
      body: { product_id: productId },
    }).then((response) => {
      console.log(JSON.stringify(response));
      if (response.data.status_code === 1) {
        props.onFavouriteRemove();
      } else {
        enqueueSnackbar(response.data.message);
      }
    });
  };

  return (
    <React.Fragment>
      {props.data.length > 0 ? (
        <GText
          med
          g5
          text={`${props.data.length} ${props.settings?.product_display}`}
          style={{ fontStyle: "italic", margin: "15px 0" }}
        />
      ) : (
        ""
      )}
      <View style={{ flex: 1, flexDirection: "row", gap: "10px" }}>
        <FlatList
          initialNumToRender={items.length}
          contentContainerStyle={{ flex: 1 }}
          keyExtractor={(items) => items.id}
          data={props.data}
          numColumns={1}
          renderItem={({ item, ind }) => (
            <StoreBox
              style={{ cursor: item?.status === 0 ? "auto" : "pointer" }}
            >
              <GView style={{ position: "relative" }}>
                {props.settings?.product == "vehicle" && (
                  <GText
                    g6
                    text={item?.header}
                    style={{
                      background: "#f5f5f5",
                      width: "auto",
                      position: "absolute",
                      padding: "2px 10px",
                      top: "0",
                      right: "0",
                    }}
                  />
                )}
                <img
                  src={
                    item?.images.length > 0
                      ? ImgUrl("item") + "/" + item?.images[0]?.photo
                      : ""
                  }
                  alt={item?.title}
                  onError={(e) => addDefaultSrc(e, "item")}
                  onClick={() => {
                    if (item?.status === 0) {
                      return;
                    }
                    props.onSelectItem(item);
                  }}
                  width="auto"
                  height={
                    props.settings?.product == "vehicle" ? "190px" : "170px"
                  }
                  style={{ width: "100%" }}
                />
              </GView>
              <div
                className="info"
                onClick={() => {
                  if (item?.status === 0) {
                    return;
                  }
                  props.onSelectItem(item);
                }}
              >
                <div className="rating">
                  {item?.rating?.count == 0 ? (
                    <p className="tag">{item?.rating?.display}</p>
                  ) : (
                    props.settings?.product != "vehicle" && (
                      <p onClick={() => props.onShowReview(item)}>
                        ★ {item?.rating?.display}({item?.rating?.count})
                      </p>
                    )
                  )}
                </div>

                <span style={{ margin: "0 5px " }}>
                  {item.favourite === true ? (
                    <FavoriteFilledIcon
                      style={{ cursor: "pointer", fill: "red" }}
                      onClick={(e) => {
                        e.stopPropagation();
                        removeFavourite(item.id);
                      }}
                    />
                  ) : (
                    <FavoriteFilledIcon
                      style={{ cursor: "pointer", fill: "#fff" }}
                    />
                  )}
                </span>

                <GText
                  semi
                  g5
                  text={item?.custom_fields?.title || item?.title}
                  style={{
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                    display: "-webkit-box",
                    WebkitLineClamp: "2",
                    WebkitBoxOrient: "vertical",
                    // whiteSpace: "nowrap",
                    padding: "0 5px",
                    fontSize: "16.5px",
                    lineHeight: "22px",
                    letterSpacing: "0.3px",
                  }}
                />

                {props.settings?.time_charges != 0 && (
                  <GText
                    med
                    text={props.settings?.currency_symbol + item?.cost}
                    style={{
                      margin: "5px 0",
                      color: "#111",
                      overflow: "hidden",
                      textOverflow: "ellipsis",
                      whiteSpace: "nowrap",
                      padding: "0 5px",
                    }}
                  />
                )}

                <GText
                  med
                  text={item?.custom_fields?.desc}
                  style={{
                    margin: "3px 0",
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                    whiteSpace: "nowrap",
                    padding: "0 5px",
                    fontSize: "14px",
                  }}
                />
              </div>

              {item?.status === 0 ? <div className="closed">Closed</div> : ""}
            </StoreBox>
          )}
          ListEmptyComponent={props.Placeholder}
          // onEndReachedThreshold={0.4}
          // onEndReached={loadMore}
        />
      </View>

      {props.pagination.next_page_url ? (
        <GButton
          variant="condensed"
          children={"Load More"}
          type="button"
          onClick={() => props.onLoadMore()}
          style={{ display: "flex", margin: "10px auto" }}
        />
      ) : (
        ""
      )}
    </React.Fragment>
  );
}
