import React from "react";
import GText from "./Elements/GText";
import Favicon from "./assets/images/favicon.png";

export default function NotFound() {
  let s = localStorage.getItem("settings");
  //console.log(s);

  s =
    s === "" || s === "undefined" || s === null || s === {} ? s : JSON.parse(s);
  console.log(s);
  if (s?.status_code === 417) {
    document.title = "FlexPrime";
    //grab favicon element by ID
    const favicon = document.getElementById("favicon");
    favicon.href = Favicon;
  }

  return (
    <div
      style={{
        display: "flex",
        textAlign: "center",
        height: "100vh",
        alignItems: "center",
      }}
    >
      <GText light g1={"true"} text={s?.message} />
    </div>
  );
}
